"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Maybe = void 0;
const is_defined_1 = require("../is-defined");
class Maybe {
    constructor(value) {
        this._value = value;
    }
    map(mapFn) {
        if ((0, is_defined_1.isValue)(this._value)) {
            return new Maybe(mapFn(this._value));
        }
        return new Maybe(undefined);
    }
    get(orElse) {
        if ((0, is_defined_1.isValue)(this._value)) {
            return this._value;
        }
        return orElse();
    }
}
exports.Maybe = Maybe;
