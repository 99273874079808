/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react'
import moment from 'moment'
import { t } from 'i18next'

import {
  Icon,
  Position
} from '@ui'
import {
  timeUtil,
  miscUtil,
  sortUtil,
  calendarUtil,
  permissionsUtil,
  isNotNull,
  notification,
  routeUtil
} from '@app/util'

import { NO_LOCALITY, NO_POSITION } from '@app/const/globals'
import Controls from '../common/controls'
import Header from '../common/header'
import { Statistics } from '../common/statistics'
import Capacities from '../common/capacities'
import ShiftTemplates from '../common/shift-templates'
import Section from '../common/section'
import connect from './connect'
import '../common/calendar-common.scss'
import { PERMISSIONS } from '@app/const'

import { SORT_BY } from '../common/sort-by'
import { formatHours } from '@app/util/format-hours'

/*
SUPPORTED PROPS
  view: if not passed, default to store.dalendar.view or to 'month'
  date: if not passed, default to store.dalendar.date or to current day moment()
*/
class CalendarManager extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      calZoom: window.localStorage.getItem('ds-calendar-zoom') || 100,
      sortedBy: window.localStorage.getItem('ds-calendar-sort') || 'SORT_BY_NAME',
      countWarnings: 0,
      countWarningsByType: { all: 0 },
      countWarningsByDate: {}
    }
    this.labelWidthRem = 9.5
    this._isMounted = false
    this._alreadyProcessedOfferOpening = false

    this.getCols = this._getCols.bind(this)
    this.getSortedRows = this._getSortedRows.bind(this)
    this.getFilteredRows = this._getFilteredRows.bind(this)
    this.getSectionRows = this._getSectionRows.bind(this)
    this.reloadWorkspaceEvents = this._reloadWorkspaceEvents.bind(this)
    this.computeWarningNumbers = this._computeWarningNumbers.bind(this)
    this.filterWarningByWarningsFilter = this._filterWarningByWarningsFilter.bind(this)
    this.cleanUpCalendarFilters = this._cleanUpCalendarFilters.bind(this)
  }

  // deletes those calendar filters that are no longer valid. specifically:
  //   - user filters for users who have been deleted
  //   - locality filters for localities that no longer exists
  //   - position filters for positions that no longer exists
  //   - type='unavailability' or type='shift' filters, since they are deprecated
  _cleanUpCalendarFilters () {
    const { calendarFilters, setCalendarFilters, employees, positions, workspace } = this.props
    const updatedCalendarFilters = []
    const needsToBeRemoved = (cf) => {
      // is user still on WS?
      if (cf.userId && Object.keys(employees).length) {
        if (!Object.keys(employees).includes(cf.userId)) return true
      }
      // is locality still on WS?
      if (cf.localityId && workspace && workspace.localities) {
        if (!workspace.localities.find(l => l.id === cf.localityId)) return true
      }
      // is position still on WS?
      if ((cf.peopleWithPositionId || (cf.data && cf.data.positionId)) && positions) {
        if (cf.peopleWithPositionId) {
          if (!positions.find(p => !p.archived && p.id === cf.peopleWithPositionId)) return true
        }
        if (cf.data && cf.data.positionId) {
          if (!positions.find(p => !p.archived && p.id === cf.data.positionId)) return true
        }
      }
      // deprecated 'type' filters
      if (cf.type && ['shift', 'unavailability'].includes(cf.type)) {
        return true
      }
      // old implementation of timeOffs (hard unavs) filter with data.type: HARD
      if (cf?.data?.type === 'HARD') {
        return true
      }
      // old implementation of the 'rowsWithoutEventsHidden' filter
      if (cf.events && cf.events.length && !cf.events.some(ev => miscUtil.safeStringify((ev) !== miscUtil.safeStringify([])))) {
        return true
      }

      return false
    }
    calendarFilters.forEach(cf => {
      if (!needsToBeRemoved(cf)) updatedCalendarFilters.push(cf)
    })

    if (miscUtil.safeStringify(updatedCalendarFilters) !== miscUtil.safeStringify(calendarFilters)) setCalendarFilters(updatedCalendarFilters)
  }

  componentDidUpdate (prevProps) {
    const { setModal, modal, offers } = this.props

    // re-compute warning numbers if calendar's date or view or no. of warnings changed
    if (
      (prevProps.calendar?.date !== this.props.calendar?.date) ||
      (prevProps.calendar?.view !== this.props.calendar?.view) ||
      (prevProps.shifts.reduce((a, s) => { return a + (s.warnings ? s.warnings.length : 0) }, 0) !== this.props.shifts.reduce((a, s) => { return a + (s.warnings ? s.warnings.length : 0) }, 0)) ||
      (prevProps.timeOffs.reduce((a, s) => { return a + (s.warnings ? s.warnings.length : 0) }, 0) !== this.props.timeOffs.reduce((a, s) => { return a + (s.warnings ? s.warnings.length : 0) }, 0))
    ) {
      setTimeout(() => {
        this.computeWarningNumbers()
      }, 500)
    }

    // display 'planning' sidebar when there is a plan
    if (['prepare', 'process'].includes(this.props?.workspace?.currentPlan?.status) && this.props.sidebar?.type !== 'planning') {
      this.props.setSidebar('planning')
    }

    const searchParams = new URLSearchParams(window.location.search)
    const isTryingToOpenOffer = searchParams.has('offer')
    const offId = searchParams.get('offer')

    if (isTryingToOpenOffer) {
      if (isNotNull(modal)) return
      if (this._alreadyProcessedOfferOpening === offId) return
      const off = offers?.find(o => o.id === offId)
      if (off) {
        setModal('offers', {
          selectedShiftIds: [off.shiftId],
          action: off.status === 'managerRequestApproval'
            ? 'approve'
            : off.status === 'managerResultApproval'
              ? 'resolve'
              : off.status === 'employeeReplies'
                ? 'show'
                : 'create',
          afterClose: () => {
            routeUtil.navigate(window.location.pathname)
          }
        }).then(() => {
          this._alreadyProcessedOfferOpening = false
        })
      } else {
        notification.warn({ code: 'offerNoLongerExists' })
        this._alreadyProcessedOfferOpening = offId
      }
    }
  }

  componentDidMount () {
    this._isMounted = true
    const { calendar, setCalendar } = this.props

    // set or update the store.calendar.[view/date] - these are used for rendering
    let { date, view } = this.props
    if (!date) {
      date = moment().startOf('day')
    } else {
      date = moment(date).startOf('day')
    }
    if (!view) {
      if (calendar && calendar.view) {
        view = calendar.view
      } else {
        view = 'month'
      }
    }

    // update the store.calendar using the route
    const routeParts = window.location.pathname.split('/').filter((p) => p && p !== '')
    let viewFromRoute = null
    let dateFromRoute = null
    if (routeParts && routeParts.length > 1) {
      if (routeParts.length >= 2) viewFromRoute = routeParts[1]
      if (routeParts.length >= 3) dateFromRoute = routeParts[2]
    }
    if (viewFromRoute && ['day', 'week', 'month'].includes(viewFromRoute)) view = viewFromRoute
    if (dateFromRoute && moment(dateFromRoute)) date = moment(dateFromRoute)

    // force 'day' view on mobile devices
    // if (window.matchMedia('only screen and (max-device-width: 768px)').matches) {
    //  console.log('Mobile device - setting calendar to day view.')
    //  view = 'day'
    // }

    // execute the store.calendar update
    if (!calendar || !moment(calendar.date).isSame(date, 'day') || calendar.view !== view || (typeof calendar.roleStats === 'undefined') || (typeof calendar.changedShiftsCount === 'undefined')) {
      setCalendar({
        date,
        view
      })
    }

    // set up the drag&drop handler 'document.ondragover'
    document.ondragover = (e) => {
      window.dragPageX = e.pageX
      window.dragPageY = e.pageY
    }

    this.reloadWorkspaceEvents()
    this.computeWarningNumbers()
    this.filterWarningByWarningsFilter()

    setTimeout(() => {
      // call the calendar filter cleanup after some time, once the employees, positions, etc. had enough time to
      // be loaded, because the cleanup function needs all that data to fully function
      this.cleanUpCalendarFilters()
    }, 5000)
  }

  componentWillUnmount () {
    this._isMounted = false

    // clear the drag&drop handler 'document.ondragover'
    document.ondragover = (e) => { }
  }

  // load the WS events (closed days and day notes)
  // and add 'is-closed' CSS class to relevant columns
  _reloadWorkspaceEvents () {
    const { loadWorkspaceEvents, calendar } = this.props
    const per = calendarUtil.getReloadPeriod(calendar)
    if (per) {
      loadWorkspaceEvents({
        type: null,
        period: per
      })
    }
  }

  // returns a list of calendar columns corresponding to days or hours (depending on the view)
  _getCols () {
    const view = this.props.calendar.view
    const date = moment(this.props.calendar.date)
    if (!view || !date) return []
    const ret = []
    if (view === 'day') {
      for (var h = 0; h <= 23; h++) {
        ret.push({
          date,
          hour: h
        })
      }
    }
    if (view === 'week') {
      const weekStart = date.startOf('week')
      for (var d = 0; d <= 6; d++) {
        ret.push({
          date: weekStart.clone().add(d, 'days')
        })
      }
    }
    if (view === 'month') {
      const monthStart = date.startOf('month')
      for (var dm = 0; dm < date.daysInMonth(); dm++) {
        ret.push({
          date: monthStart.clone().add(dm, 'days')
        })
      }
    }
    return ret
  }

  _getEmployeeIcon (ee) {
    if (ee.employeeWarnings && ee.employeeWarnings.length) {
      if (ee.employeeWarnings.length === 1 && ee.employeeWarnings[0].type === 'dummy') return null

      const redWarningTypes = [
        'workTimeAvg',
        'workSaldo',
        'outOfContract',
        'overtimeReached',
        'underUtilized',
        'underUtilizedShifts'
      ]
      let extraClass = 'is-minor-warning'
      ee.employeeWarnings.forEach(w => {
        if (redWarningTypes.includes(w.type)) extraClass = 'is-major-warning'
      })
      return { ico: 'exclamation', extraClass }
    }
    return null
  }

  // returns a list of rows with all the content for one of the sections
  _getSectionRows (whichSection, cols, dateStringsInPeriod, shiftsInPeriod, availsInPeriod, timeOffsInPeriod) {
    const { employees, calendarFilters, isPluginEnabled } = this.props
    const { view, date } = this.props.calendar

    let ret = []

    // top sections
    if (['unassigned', 'offers'].includes(whichSection)) {
      // row with unassigned shifts
      if (whichSection === 'unassigned') {
        const unassignedShifts = shiftsInPeriod.filter((ev) => !ev.userId && !ev.hasOffer)
        const newRowUnass = {
          labelData: {
            icon: 'clipboard',
            title: t('OPEN_SHIFTS')
          },
          events: cols.map((col) => []),
          employee: 'unassigned'
        }
        let unassignedMins = 0
        unassignedShifts.map((ev) => {
          if (!ev || !ev.period || !ev.period.start || !ev.period.end) return
          let colNum = null
          if (view === 'day') {
            colNum = moment(ev.period.start).hour()
          } else {
            colNum = dateStringsInPeriod.indexOf(moment(ev.period.start).format('YYYY-MM-DD'))
          }
          unassignedMins += moment(ev.period.end).diff(ev.period.start) / 60000

          const evts = newRowUnass.events[colNum]
          if (evts) {
            newRowUnass.events[colNum].push({
              type: 'shift',
              data: ev
            })
          }
        })
        newRowUnass.labelData.text = formatHours(unassignedMins / 60) + ' / ' + t('X_SHIFTS', { x: unassignedShifts.length.toString() })
        ret.push(newRowUnass)
      }

      // row with offers
      if (whichSection === 'offers' && isPluginEnabled('offers')) {
        const offeredShifts = shiftsInPeriod.filter((ev) => ev.hasOffer && ev.hasOffer.status !== 'resolved')
        const newRowOff = {
          labelData: {
            icon: 'clipboard',
            title: t('CONTESTS')
          },
          events: cols.map((col) => []),
          employee: 'offers'
        }
        let offerMins = 0

        offeredShifts.map((ev) => {
          if (!ev || !ev.period || !ev.period.start || !ev.period.end) return
          let colNum = null
          if (view === 'day') {
            colNum = moment(ev.period.start).hour()
          } else {
            colNum = dateStringsInPeriod.indexOf(moment(ev.period.start).format('YYYY-MM-DD'))
          }
          offerMins += moment(ev.period.end).diff(ev.period.start) / 60000
          const evts = newRowOff.events[colNum]
          if (evts) {
            newRowOff.events[colNum].push({
              type: 'shift',
              data: ev
            })
          }
        })

        newRowOff.labelData.text = formatHours(offerMins / 60) + ' / ' + t('X_SHIFTS', { x: offeredShifts.length.toString() })
        ret.push(newRowOff)
      }
    }

    // bottom section
    if (whichSection === 'bottom') {
      // one row per employee
      sortUtil.sortEmployees(employees).map((ee) => {
        const empIco = this._getEmployeeIcon(ee)

        const filteredShiftsInPeriod = calendarUtil.getFilteredEvents(shiftsInPeriod.filter((ev) => ev.userId === ee.id), calendarFilters)
        const filteredAvailsInPeriod = calendarUtil.getFilteredEvents(availsInPeriod.filter((ev) => ev.userId === ee.id), calendarFilters)
        const filteredTimeOffsInPeriod = calendarFilters.some(f => f.type === 'timeOff')
          ? []
          : calendarUtil.getFilteredEvents(timeOffsInPeriod.filter((ev) => ev.userId === ee.id), calendarFilters)

        // this is used for 2 things:
        //   - one of the calendar filters and
        //   - skipping external emps without shifts
        const userShiftsCount = filteredShiftsInPeriod.length

        // this is used for calendar filters
        const userEventsCount = userShiftsCount + filteredAvailsInPeriod.length + filteredTimeOffsInPeriod.length

        // this is used for 2 things:
        //   - by one of the calendar filters to hide the users that have no active contracts in displayed calendar period
        //   - by Row when displaying the ends and starts of user's contracts
        const userHasContractsInCalendarPeriod = Boolean(ee.contracts && ee.contracts.some(con =>
          (con.period) &&
          (!con.period.start || moment(con.period.start).isSameOrBefore(moment(date).endOf(view))) &&
          (!con.period.end || moment(con.period.end).isSameOrAfter(moment(date).startOf(view)))))

        // skip external employees with no events
        if (ee.external && userEventsCount === 0) return

        // initialize row contents and set the data for label
        const newRow = {
          labelData: {
            icon: empIco ? empIco.ico : null,
            iconExtraClass: empIco ? empIco.extraClass : null,
            user: ee,
            userShiftsCount,
            userEventsCount,
            userHasContractsInCalendarPeriod,
            title: ee.name,
            positions: ee.positions
          },
          events: cols.map((col) => []),
          employee: ee.id
        }
        ret.push(newRow)
      })
    }

    // filtering the rows
    ret = this.getFilteredRows(ret)

    // sorting rows in bottom section
    if (whichSection === 'bottom') {
      ret = this.getSortedRows(ret, cols, shiftsInPeriod)
    }

    return ret
  }

  _getFilteredRows (rows) {
    const { calendarFilters } = this.props
    if (!calendarFilters || !calendarFilters.length) return rows

    const filteredLocalities = calendarFilters.filter(fil => fil.localityId && fil.localityId !== 'noLocality').map(fil => fil.localityId)
    const filteredPositions = calendarFilters.filter(fil => fil.peopleWithPositionId && fil.peopleWithPositionId !== NO_POSITION).map(fil => fil.peopleWithPositionId)

    return rows.map(row => {
      // first, deal with special 'userId' filters: skip the whole rows if row.employee matches
      if (calendarFilters.find(fil => fil.userId && (!['offers', 'unassigned'].includes(fil.userId)) && fil.userId === row.employee)) return null

      // also, deal with special 'labelData' filters similarly: skip the whole rows
      const labelDataFilters = calendarFilters.filter(fil => fil.labelData)
      if (labelDataFilters.find(fil => {
        const rowWithFilterApplied = miscUtil.mergeDeep(row, fil)
        return (miscUtil.safeStringify(row) === miscUtil.safeStringify(rowWithFilterApplied))
      })) return null

      // also, deal with the special 'peopleWithPositionId' filters: skip the whole rows if
      // there are some position filters active and the corresponding user doesn't
      // have position that's not filtered out
      if (!['unassigned', 'offers'].includes(row.employee) && calendarFilters.find(fil => fil.peopleWithPositionId)) {
        // skip users without position if NO_POSITION filter is set
        if (calendarFilters.find(fil => fil.peopleWithPositionId === NO_POSITION)) {
          if (!row.labelData.user || !row.labelData.user.positions || !row.labelData.user.positions.length) {
            return null
          }
        }
        // skip users with some positions, but no remaining position after applying positionId filters
        if (row.labelData.user && row.labelData.user.positions && row.labelData.user.positions.length) {
          const remainingUsersPositions = row.labelData.user.positions.filter(pos => !filteredPositions.includes(pos.id))
          if (remainingUsersPositions.length === 0) return null
        }
      }

      // also, deal with the special 'localityId' filters: skip the whole rows if
      // there are some locality filters active and the corresponding user doesn't
      // have locality that's not filtered out
      if (!['unassigned', 'offers'].includes(row.employee) && calendarFilters.find(fil => fil.localityId)) {
        // skip users without locality if NO_LOCALITY filter is set
        if (calendarFilters.find(loc => loc.localityId === NO_LOCALITY)) {
          if (!row.labelData.user || !row.labelData.user.localities || !row.labelData.user.localities.length) {
            return null
          }
        }
        // skip users with some localities, but no remaining locality after applying localityId filters
        if (row.labelData.user && row.labelData.user.localities && row.labelData.user.localities.length) {
          const remainingUsersLocalities = row.labelData.user.localities.filter(locId => !filteredLocalities.includes(locId))
          if (remainingUsersLocalities.length === 0) return null
        }
      }

      return row
    }).filter(row => !!row)
  }

  _getSortedRows (rows, cols, shiftsInPeriod) {
    const { positions, workspace, calendarFilters, calendar } = this.props
    let ret = rows

    // by name
    if (this.state.sortedBy === SORT_BY.NAME) {
      // nothing to do here - the 'ret' is already sorted by name
    }

    // by start of 1st shift
    if (this.state.sortedBy === SORT_BY.SHIFT_START) {
      const empsFirstShiftTime = {}
      shiftsInPeriod.forEach(s => {
        if (!s.userId) return
        if (!empsFirstShiftTime[s.userId]) {
          empsFirstShiftTime[s.userId] = s.period.start
        } else {
          if (moment(empsFirstShiftTime[s.userId]).isAfter(s.period.start)) empsFirstShiftTime[s.userId] = s.period.start
        }
      })

      ret = ret.sort((a, b) => {
        if (!empsFirstShiftTime[a.employee] && empsFirstShiftTime[b.employee]) return 1
        if (empsFirstShiftTime[a.employee] && !empsFirstShiftTime[b.employee]) return -1
        return moment(empsFirstShiftTime[a.employee]).isAfter(empsFirstShiftTime[b.employee]) ? 1 : -1
      })
    }

    // by position
    if (this.state.sortedBy === SORT_BY.POSITION) {
      let newRet = []
      positions.filter(p => !p.archived).sort((a, b) => { return a.name > b.name ? 1 : -1 }).map((pos) => {
        if (calendarFilters.find(fil => fil.peopleWithPositionId === pos.id)) return null

        // get rows for this section and add sort-related data to them (they're then
        // used to make events irrelevant for this section transparent)
        const matchingRows = ret.filter((r) => r.labelData.user.positions && r.labelData.user.positions.find(p => p.id === pos.id)).map(row => {
          return Object.assign({}, row, { sortData: { sortedBy: 'SORT_BY_POSITION', id: pos.id } })
        })

        // add position name section separator
        newRet.push({
          divider: true,
          dividerContent: <Position id={pos.id} name={pos.name} color={pos.color} />,
          events: cols.map((col) => [])
        })
        newRet = newRet.concat(matchingRows)
      })

      // section for emps with no position
      if (!calendarFilters.find(fil => fil.peopleWithPositionId === NO_POSITION)) {
        newRet.push({
          divider: true,
          dividerContent: t('SORT_BY_POSITION_NO_POS'),
          events: cols.map((col) => [])
        })
        newRet = newRet.concat(ret.filter((r) => !r.labelData.user.positions || r.labelData.user.positions.length === 0))
      }

      ret = newRet
    }

    // by locality
    if (this.state.sortedBy === SORT_BY.LOCALITY) {
      let newRet = []

      if (workspace && workspace.localities) {
        workspace.localities.map((ct) => {
          if (calendarFilters.find(fil => fil.localityId === ct.id)) return null

          // get rows for this section and add sort-related data to them (they're then
          // used to make events irrelevant for this section transparent)
          const matchingRows = ret.filter((r) => r.labelData.user.localities && r.labelData.user.localities.includes(ct.id)).map(row => {
            return Object.assign({}, row, { sortData: { sortedBy: 'SORT_BY_LOCALITY', id: ct.id } })
          })

          // add locality name section separator
          if (matchingRows.length) {
            newRet.push({
              divider: true,
              dividerContent: <div className='ds-locality-divider'><Icon ico='location' />{'(' + ct.shortName + ') ' + ct.name}</div>,
              events: cols.map((col) => [])
            })
          }
          newRet = newRet.concat(matchingRows)
        })
      }

      // section for emps with no locality
      if (!calendarFilters.find(fil => fil.localityId === NO_LOCALITY)) {
        newRet.push({
          divider: true,
          dividerContent: <div className='ds-locality-divider'><Icon ico='location' />{t('SORT_BY_LOCALITY_NO_LOC')}</div>,
          events: cols.map((col) => [])
        })
        newRet = newRet.concat(ret.filter((r) => !r.labelData.user.localities || r.labelData.user.localities.length === 0))
      }

      ret = newRet
    }

    // by contract type
    if (this.state.sortedBy === SORT_BY.CONTRACT) {
      let newRet = []
      const calendatDisplayedPeriodStart = moment(calendar?.date).startOf(calendar?.view)
      const calendatDisplayedPeriodEnd = moment(calendar?.date).endOf(calendar?.view)

      if (workspace && workspace.contractTypes) {
        workspace.contractTypes.map((ct) => {
          const matchingRows = ret.filter((r) => {
            const activeContracts = (r.labelData?.user?.contracts || []).filter(c => !c.archived && !((c.period?.start && moment(c.period.start).isAfter(calendatDisplayedPeriodEnd)) || (c.period?.end && moment(c.period.end).isBefore(calendatDisplayedPeriodStart))))
            return activeContracts.some(c => c.type === ct.id)
          }).map(row => {
            return Object.assign({}, row, { sortData: { sortedBy: 'SORT_BY_CONTRACT', id: ct.id } })
          })
          // add contract name separator
          if (ct.id !== 'default' || matchingRows.length) {
            newRet.push({
              divider: true,
              dividerContent: <div className='ds-contract-divider'><div>{t(ct.name)}</div></div>,
              events: cols.map((col) => [])
            })
          }
          newRet = newRet.concat(matchingRows)
        })
      }

      // section for emps with no active contract
      // const noContrUsrs = ret.filter((r) => !r.labelData.user.contractId)
      const noContrUsrs = ret.filter((r) => {
        const activeContracts = (r.labelData?.user?.contracts || []).filter(c => !c.archived && !((c.period?.start && moment(c.period.start).isAfter(calendatDisplayedPeriodEnd)) || (c.period?.end && moment(c.period.end).isBefore(calendatDisplayedPeriodStart))))
        return activeContracts.length === 0
      })

      if (noContrUsrs.length) {
        newRet.push({
          divider: true,
          dividerContent: <div className='ds-locality-divider'>{t('SORT_BY_CONTRACT_NO_CON')}</div>,
          events: cols.map((col) => [])
        })
        newRet = newRet.concat(noContrUsrs)
      }

      ret = newRet
    }

    // by day part
    if (this.state.sortedBy === SORT_BY.DAY_PART) {
      const empsInDayParts = {}
      const dayParts = timeUtil.getDayParts()
      dayParts.forEach((dp) => {
        empsInDayParts[dp.name] = []
      })

      shiftsInPeriod.forEach(ev => {
        if (!ev.userId) return
        const dur = ev.duration || moment.duration(moment(ev.period.end).diff(ev.period.start), 'minutes')
        const middleTime = moment(ev.period.start).add(dur / 2, 'minutes')
        dayParts.forEach((dp) => {
          let timeCond = false
          if (dp.conditionOperator === 'AND') {
            if (middleTime.format('HH:mm:ss') >= dp.start && middleTime.format('HH:mm:ss') < dp.end) timeCond = true
          }
          if (dp.conditionOperator === 'OR') {
            if (middleTime.format('HH:mm:ss') >= dp.start || middleTime.format('HH:mm:ss') < dp.end) timeCond = true
          }
          if (timeCond && !empsInDayParts[dp.name].includes(ev.userId)) {
            empsInDayParts[dp.name].push(ev.userId)
          }
        })
      })

      let newRet = []
      dayParts.map((dp) => {
        // add day part name separator
        newRet.push({
          divider: true,
          dividerContent: <div className='ds-part-of-day-divider'><Icon ico={dp.ico} /><div>{t(dp.name)}</div></div>,
          events: cols.map((col) => [])
        })
        newRet = newRet.concat(
          ret.filter(r => empsInDayParts[dp.name].includes(r.employee))
        )
      })

      newRet.push({
        divider: true,
        dividerContent: <div className='ds-part-of-day-divider'><Icon ico={Icon.ICONS.close1} /><div>{t('SORT_BY_DAY_PART_NO_SHIFTS')}</div></div>,
        events: cols.map((col) => [])
      })
      newRet = newRet.concat(
        ret.filter((r) => {
          let ok = true
          dayParts.forEach((dp) => {
            if (empsInDayParts[dp.name].includes(r.employee)) {
              ok = false
            }
          })
          return ok
        })
      )

      ret = newRet
    }

    // by custom ordering
    if (this.state.sortedBy === SORT_BY.CUSTOM) {
      ret = ret.sort((a, b) => {
        const valA = a.labelData && a.labelData.user && (typeof a.labelData.user.calendarOrder !== typeof null) ? a.labelData.user.calendarOrder : 9999999
        const valB = b.labelData && b.labelData.user && (typeof b.labelData.user.calendarOrder !== typeof null) ? b.labelData.user.calendarOrder : 9999999
        return valA > valB ? 1 : (valB > valA ? -1 : 0)
      })
    }

    // finally, separate the external emps from the end of the list
    // by their own separator
    ret = ret.sort((a, b) => {
      return (!(a.labelData && a.labelData.user && a.labelData.user.external) && (b.labelData && b.labelData.user && b.labelData.user.external)) ? -1 : 0
    })
    const firstExternalIdx = ret.findIndex(r => r.labelData && r.labelData.user && r.labelData.user.external)
    if (firstExternalIdx !== -1) {
      ret.splice(firstExternalIdx, 0, {
        divider: true,
        dividerContent: <div className='ds-external-divider'><div>{t('EXTERNAL_EX_EMPLOYEES').replace(':', '')}</div></div>,
        events: cols.map((col) => [])
      })
    }

    return ret
  }

  _computeWarningNumbers () {
    if (!this._isMounted) return
    const { shifts, timeOffs, calendarFilters } = this.props

    // get columns and relevant dates
    const cols = this.getCols()
    const dateStringsInPeriod = cols.map((col) => col.date.format('YYYY-MM-DD'))

    // get events in relevant period
    const shiftsInPeriod = shifts ? shifts.filter((s) => s.period && s.period.start && dateStringsInPeriod.includes(moment(s.period.start).format('YYYY-MM-DD'))) : []
    const timeOffsInPeriod = timeOffs ? timeOffs.filter((rec) => rec?.period?.start && dateStringsInPeriod.includes(moment(rec.period.start).format('YYYY-MM-DD'))) : []
    let eventsInPeriod = shiftsInPeriod.concat(timeOffsInPeriod)

    // filter out the events according to active calendarFilters
    if (calendarFilters && calendarFilters.length) {
      eventsInPeriod = calendarUtil.getFilteredEvents(eventsInPeriod, calendarFilters, false)
    }

    const countWarningsByType = { all: 0 }
    let countWarningsByDate = {}
    eventsInPeriod.filter(s => s.warnings && s.warnings.length > 0).forEach(s => {
      s.warnings.forEach(w => {
        if (!countWarningsByType[w.name]) countWarningsByType[w.name] = 0
        countWarningsByType[w.name] += 1
        countWarningsByType.all += 1
        countWarningsByDate[moment(s.period.start).format('YYYY-MM-DD')] = {
          ...countWarningsByDate[moment(s.period.start).format('YYYY-MM-DD')],
          [w.name]: +1
        }
      })
    })
    countWarningsByDate = this.filterWarningByWarningsFilter(countWarningsByDate)
    this.setState((s) => Object.assign({}, s, {
      countWarnings: countWarningsByType.all,
      countWarningsByType,
      countWarningsByDate
    }))
  }

  _filterWarningByWarningsFilter (countWarningsByDate) {
    const { calendarFilters } = this.props
    const hiddenWarnings = calendarFilters.find(filter => Array.isArray(filter?.hideWarnings) && filter?.hideWarnings.length)
    const filteredWarnings = {}
    if (!countWarningsByDate) return
    for (const date of Object.keys(countWarningsByDate)) {
      let tempArray = {}
      for (const type of Object.keys(countWarningsByDate[date])) {
        if (!hiddenWarnings?.hideWarnings?.includes(type)) {
          tempArray = {
            ...tempArray,
            [type]: countWarningsByDate[date][type]
          }
        }
      }
      filteredWarnings[date] = tempArray
    }
    return filteredWarnings
  }

  render () {
    const {
      className, shifts, offers, calendar, availabilities, timeOffs
    } = this.props
    const cN = ['ds-calendar', 'is-manager-calendar']
    if (className) cN.push(className)

    // get columns and relevant dates
    const cols = this.getCols()
    const dateStringsInPeriod = cols.map((col) => col.date.format('YYYY-MM-DD'))

    // get shifts, availabilities and timeOffs in relevant period
    const shiftsInPeriod = shifts ? shifts.filter((s) => s.period && s.period.start && dateStringsInPeriod.includes(moment(s.period.start).format('YYYY-MM-DD'))).map(s => Object.assign({}, s, { hasOffer: undefined })) : []
    const availsInPeriod = availabilities ? availabilities.filter((rec) => rec?.period?.start && dateStringsInPeriod.includes(moment(rec.period.start).format('YYYY-MM-DD'))) : []
    const timeOffsInPeriod = timeOffs ? timeOffs.filter((rec) => rec?.period?.start && dateStringsInPeriod.includes(moment(rec.period.start).format('YYYY-MM-DD'))) : []

    // add offers data to the shifts
    offers.forEach((off) => {
      if (off.shiftId) {
        const idx = shiftsInPeriod.findIndex((s) => s.id === off.shiftId)
        if (idx !== -1) {
          // if there is an offer at this shift, set shift's hasOffer
          if (off.status !== 'resolved') {
            shiftsInPeriod[idx].hasOffer = off
          }
        }
      }
    })

    return (
      <Fragment>
        {/* Controls */}
        <Controls
          warningCounts={this.state.countWarnings}
          countWarningsByType={this.state.countWarningsByType}
          calZoom={this.state.calZoom}
          calZoomOnChange={(val) => {
            window.localStorage.setItem('ds-calendar-zoom', val)
            this.setState(s => Object.assign({}, s, { calZoom: val }))
          }}
        />
        <div className={cN.join(' ')}>
          {/* Statistics or Capacities */}
          {calendar.displayStatistics
            ? (
              <Statistics
                shiftsInPeriod={shiftsInPeriod.filter(s => s.userId && !(s.hasOffer && s.hasOffer.transfer && s.hasOffer.transfer.master) /* skip unassigned shifts and shifts from external transfers */)}
                timeOffsInPeriod={timeOffsInPeriod}
                dateStringsInPeriod={dateStringsInPeriod}
                labelWidthRem={this.labelWidthRem}
                style={{
                  zoom: (!isNaN(parseInt(this.state.calZoom)) && parseInt(this.state.calZoom) !== 100) ? this.state.calZoom.toString() + '%' : undefined
                }}
              />
            )
            : calendar.displayCapacities
              ? (
                <Capacities
                  dateStringsInPeriod={dateStringsInPeriod}
                  labelWidthRem={this.labelWidthRem}
                  style={{
                    zoom: (!isNaN(parseInt(this.state.calZoom)) && parseInt(this.state.calZoom) !== 100) ? this.state.calZoom.toString() + '%' : undefined
                  }}
                />
              )
              : null}

          {/* Shift Templates Row */}
          {calendar.displayRowTemplates && permissionsUtil.canWrite(PERMISSIONS.CALENDAR) ? (
            <ShiftTemplates
              cols={cols}
              labelWidthRem={this.labelWidthRem}
              style={{
                zoom: (!isNaN(parseInt(this.state.calZoom)) && parseInt(this.state.calZoom) !== 100) ? this.state.calZoom.toString() + '%' : undefined
              }}
            />
          ) : null}

          {/* Header */}
          <Header
            cols={cols}
            warningsByDate={this.state.countWarningsByDate}
            sortedBy={this.state.sortedBy}
            handleSortChange={(val) => {
              this.setState((s) => Object.assign({}, s, { sortedBy: val }))
              this.props.loadRoleStatsMulti(calendar.date)
            }}
            style={{
              zoom: (!isNaN(parseInt(this.state.calZoom)) && parseInt(this.state.calZoom) !== 100) ? this.state.calZoom.toString() + '%' : undefined
            }}
          />

          {/* Sections */}
          <div
            className='ds-c-sections'
            style={{
              zoom: (!isNaN(parseInt(this.state.calZoom)) && parseInt(this.state.calZoom) !== 100) ? this.state.calZoom.toString() + '%' : undefined,
              maxHeight: calendarUtil.getComputedCalendarSectionsHeight(calendar)
            }}
          >
            {calendar.displayRowUnassigned ? (
              <div className='ds-section-wrapper ds-section-wrapper-unassigned'>
                <Section
                  whichSection='unassigned'
                  rows={this.getSectionRows('unassigned', cols, dateStringsInPeriod, shiftsInPeriod, availsInPeriod, timeOffsInPeriod)}
                  cols={cols}
                  labelWidthRem={this.labelWidthRem}
                  shiftsInPeriod={shiftsInPeriod}
                  availsInPeriod={availsInPeriod}
                  timeOffsInPeriod={timeOffsInPeriod}
                  dateStringsInPeriod={dateStringsInPeriod}
                />
              </div>
            ) : null}
            {calendar.displayRowOffers ? (
              <div className='ds-section-wrapper ds-section-wrapper-offers'>
                <Section
                  whichSection='offers'
                  rows={this.getSectionRows('offers', cols, dateStringsInPeriod, shiftsInPeriod, availsInPeriod, timeOffsInPeriod)}
                  cols={cols}
                  labelWidthRem={this.labelWidthRem}
                  shiftsInPeriod={shiftsInPeriod}
                  availsInPeriod={availsInPeriod}
                  timeOffsInPeriod={timeOffsInPeriod}
                  dateStringsInPeriod={dateStringsInPeriod}
                />
              </div>
            ) : null}
            <Section
              whichSection='bottom'
              rows={this.getSectionRows('bottom', cols, dateStringsInPeriod, shiftsInPeriod, availsInPeriod, timeOffsInPeriod)}
              cols={cols}
              labelWidthRem={this.labelWidthRem}
              shiftsInPeriod={shiftsInPeriod}
              availsInPeriod={availsInPeriod}
              timeOffsInPeriod={timeOffsInPeriod}
              dateStringsInPeriod={dateStringsInPeriod}
              sortedBy={this.state.sortedBy}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default connect(CalendarManager)
