import React from 'react'
import classNames from 'classnames'

import './style.scss'

export const HeadCell = ({
  isFilter,
  column,
  children,
  isLast,
  isSticky,
  stickyOffset,
  isNextSticky
}) => {
  const lastSticky = isLast && isSticky
  const withShadow = !isLast && !isNextSticky && isSticky
  const headerProps = { ...column.getHeaderProps() }

  return (
    <div
      className={classNames(
        'head-cell',
        `head-cell-${column.key || column.name || column.id}`,
        {
          sticky: isSticky,
          'last-sticky-detection': lastSticky,
          'with-shadow-detection': withShadow,
          'last-sticky': lastSticky,
          'is-filter': isFilter,
          'with-border': !!column.groupBorder,
          'is-selection': column.id === 'selection'
        }
      )}
      {...headerProps}
      style={{
        flex: column.id === 'selection' || lastSticky ? 'initial' : '1 0 auto',
        width: column.width,
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        ...(isSticky ? { left: stickyOffset || 0 } : {})
      }}
    >
      {children}
    </div>
  )
}
