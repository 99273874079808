import React from 'react'
import classNames from 'classnames'

import { SidebarContainer } from '@app/component/sidebars'
import { ModalContainer } from '@app/component/modals'
import { Navigation } from '@core/nav'
import { Loader } from '@ui'

import connect from './connect'
import './index.scss'
import { ModalProvider } from '@app/util/modal/modal-context'

const App = ({
  full,
  noPadding,
  noScroll,
  auth,
  hideNav,
  children,
  noAnimation
}) => {
  const onScroll = (e) => {
    if (noScroll) return
    e.preventDefault()
  }

  return (
    <div
      className={classNames(
        'ds-app',
        {
          'is-full': full,
          'no-padding': noPadding
        }
      )}
      onScroll={onScroll}
    >
      <ModalProvider>
        <ModalContainer />
        <SidebarContainer />
        {auth && !hideNav && <Navigation />}
        <div className={'ds-app-scroll' + (noScroll ? ' is-no-scroll' : '')}>
          <div>
            <React.Suspense fallback={<Loader size={Loader.SIZES.LARGE} type={Loader.TYPES.OVERLAY} />}>
              <div className='ds-app-content'>
                {children}
              </div>
            </React.Suspense>
          </div>
        </div>
      </ModalProvider>
    </div>
  )
}

export default connect(App)
