"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFloatable = void 0;
const react_1 = require("react");
const react_dom_interactions_1 = require("@floating-ui/react-dom-interactions");
const react_dom_1 = require("@floating-ui/react-dom");
const useFloatable = (callback) => {
    const [open, toggleOpen] = (0, react_1.useState)(false);
    const { x, y, context, reference, floating, strategy } = (0, react_dom_interactions_1.useFloating)({
        open,
        onOpenChange: handleOpen,
        strategy: 'fixed',
        placement: 'bottom-start',
        middleware: [(0, react_dom_1.flip)()],
        whileElementsMounted: (reference, floating, update) => (0, react_dom_1.autoUpdate)(reference, floating, update, {
            animationFrame: true
        })
    });
    function handleOpen(state) {
        // onClose callback
        if (!state && !!callback)
            callback();
        toggleOpen(state);
    }
    const { getReferenceProps, getFloatingProps } = (0, react_dom_interactions_1.useInteractions)([
        (0, react_dom_interactions_1.useClick)(context),
        (0, react_dom_interactions_1.useDismiss)(context, {
            outsidePointerDown: true
        })
    ]);
    const floatAnchor = Object.assign({}, getReferenceProps({ ref: reference }));
    const floatContainer = Object.assign({}, getFloatingProps({
        ref: floating,
        style: {
            position: strategy,
            left: x !== null && x !== void 0 ? x : 0,
            top: y !== null && y !== void 0 ? y : 0
        }
    }));
    return {
        open,
        setOpen: handleOpen,
        floatAnchor,
        floatContainer
    };
};
exports.useFloatable = useFloatable;
