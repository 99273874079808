import store from '../store'
import isLoading from './is-loading'
import requestShiftTemplateCreate from '../request/shift-template-create'
import addError from '../ac/add-error'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'

// NOTE: This creates a new Shift Template object on the WS.
//       If you want to create a new shift for a Day Template, use the '/ac/create-template-shift.js'
// NOTE2: shift 1.5 uses ac/shift-1.5/create-shift-template

export default (shiftData, templateName) => {
  return async (dispatch) => {
    const { workspaceId } = store.getState()
    if (!workspaceId || !shiftData) return

    dispatch(isLoading('create-shift-template'))

    const result = await requestShiftTemplateCreate({
      workspace: workspaceId,
      name: templateName,
      data: {
        period: shiftData.period,
        position: shiftData.positionId,
        locality: shiftData.localityId,
        idealSkill: shiftData.idealSkill,
        pauses: shiftData.pauses,
        pausesFixed: shiftData.pausesFixed,
        overTime: shiftData.overTime,
        standBy: shiftData.standBy,
        standByActivities: shiftData.standByActivities,
        customAttributes: shiftData.customAttributes,
        note: shiftData.note,
        agenda: shiftData.agenda
      }
    })
    if (result && result.error) {
      dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('create-shift-template', true))
  }
}
