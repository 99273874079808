import { fetchRequest, errorUtil } from '@app/util'

const query = `query OrganizationLockHistory($organizationId: ID!, $period: PeriodInput!) {
  organization(id: $organizationId) {
    countries {
      id
      workspaces {
        id
        lockHistory(period: $period) {
          by
          level
          type
          on
          until
        }
      }
    }
  }
}`

async function requestOrganizationLockHistory (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = await response
  if (errors) {
    return errorUtil.apiError('organization', errors)
  }
  return data.organization
}

export default requestOrganizationLockHistory
