"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMyLocalities = void 0;
const useWorkspace_1 = require("@app/util/hooks/useWorkspace");
const util_1 = require("@app/util");
const store_1 = require("@app/store");
const shift_context_1 = require("../shift-context");
const react_hook_form_1 = require("react-hook-form");
const useMyLocalities = () => {
    var _a, _b;
    const workspace = (0, useWorkspace_1.useWorkspace)();
    const me = (0, store_1.useRootSelector)(state => state.me);
    const { formControl } = (0, shift_context_1.useShift)();
    const localityId = (0, react_hook_form_1.useWatch)({ control: formControl, name: 'locality' });
    const localities = (_b = (_a = workspace === null || workspace === void 0 ? void 0 : workspace.localities) === null || _a === void 0 ? void 0 : _a.filter(util_1.isValue)) !== null && _b !== void 0 ? _b : [];
    const shiftNotAtLocalityOption = {
        value: undefined,
        label: (0, util_1.t)('LOCALITY_SHIFT_NOT_AT_LOC')
    };
    const tempLocalityOptions = localities.map(locality => ({
        value: locality.id,
        label: `${(0, util_1.isValue)(locality.shortName) ? `(${locality.shortName})` : ''} ${locality.name}`
    }));
    tempLocalityOptions.unshift(shiftNotAtLocalityOption);
    if (workspace === null || workspace === void 0 ? void 0 : workspace.enforceLocalities) {
        const myLocalities = localities.filter(wsLoc => { var _a; return (_a = wsLoc.assigns) === null || _a === void 0 ? void 0 : _a.some(ass => ass.userId === me.id); });
        const filteredLocalitiOptions = tempLocalityOptions.filter(l => {
            if (!l.value)
                return true;
            if (l.value === localityId)
                return true;
            return myLocalities.some(ml => ml.id === l.value);
        });
        return filteredLocalitiOptions;
    }
    return tempLocalityOptions;
};
exports.useMyLocalities = useMyLocalities;
