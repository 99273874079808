"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAC = void 0;
const createAC = (e) => 
// "action arguments wrapper" function
(...args) => 
// dispatch injection
(dispatch) => {
    // finally your code
    // @ts-expect-error
    return e(dispatch, ...args);
};
exports.createAC = createAC;
