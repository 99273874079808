import moment from 'moment'
import { t } from 'i18next'
import {
  miscUtil,
  timeUtil,
  permissionsUtil,
  isValue
} from '@app/util'
import { PERMISSIONS } from '@app/const'
import templatesStyles from '@ui/calendar/common/shift-templates/index.scss'
import capacitiesStyles from '@ui/calendar/common/capacities/index.scss'
import statsStyles from '@ui/calendar/common/statistics/index.scss'
import copyShiftPeriod from '@app/ac/copy-shift-period'
import store from '@app/store'

function getReloadPeriod (calendar) {
  if (!calendar || !calendar.view || !calendar.date) return null
  return {
    start: moment(calendar.date).startOf(calendar.view),
    end: moment(calendar.date).endOf(calendar.view)
  }
}

function getIdentifierSeparator (escapedForCSS = false) {
  return escapedForCSS ? '\\|' : '|'
}

function getColIdentifier (date, userId, escapedForCSS = false) {
  return 'col' + this.getIdentifierSeparator(escapedForCSS) + moment(date).format('YYYY-MM-DD') + (userId ? this.getIdentifierSeparator(escapedForCSS) + userId : '')
}

function getEvtIdentifier (evtId, date, escapedForCSS = false) {
  return 'evt' + this.getIdentifierSeparator(escapedForCSS) + evtId + this.getIdentifierSeparator(escapedForCSS) + moment(date).format('YYYY-MM-DD')
}

function getElementsColIdentifier (elem) {
  const found = Array.prototype.filter.call(elem.classList, (cls) => cls.includes('col' + this.getIdentifierSeparator()))
  if (!found || found.length === 0) {
    return null
  } else {
    return found
  }
}

function getElementsEvtIdentifier (elem) {
  if (!elem) return null
  const found = Array.prototype.filter.call(elem.classList, (cls) => cls.includes('evt' + this.getIdentifierSeparator()))
  if (!found || found.length === 0) {
    return null
  } else {
    return found
  }
}

// get the availability or timeOff object and return its category name and tag
function getTimeOffOrAvailCategory (evt, workspace) {
  const wsTimeOffCats = workspace && workspace.unavailabilityCategories ? workspace.unavailabilityCategories : []
  let name = ''
  let tag = null
  let vacation = false
  const wsCat = evt.categoryId ? wsTimeOffCats.find(c => c.id === evt.categoryId) : null

  // if we have 'categoryId' and WS has corresponding category, just use it (USUAL CASE)
  if (wsCat) {
    name = wsCat.title
    tag = wsCat.tag
    vacation = wsCat.vacation
  } else {
    // if that category is missing, or we don't have 'categoryId' at all, try
    // using some fallback default values
    if (evt.category === 'vacation') {
      const defaultCat = wsTimeOffCats.find(c => c.id === 'dddddddddddddddddddddddd')
      name = defaultCat ? defaultCat.title : t('UNAV_VACATION')
      tag = defaultCat ? defaultCat.tag : null
      vacation = true
    } else if (evt.category === 'sick') {
      const defaultCat = wsTimeOffCats.find(c => c.id === 'cccccccccccccccccccccccc')
      name = defaultCat ? defaultCat.title : t('UNAV_SICK')
      tag = defaultCat ? defaultCat.tag : null
      vacation = false
    } else {
      // or just return the default availability
      name = t('UNAV_UNAVAILABLE' + (evt.available ? '_AVAIL' : ''))
      tag = null
      vacation = false
    }
  }
  return { name, tag, vacation }
}

// get the avalability & timeOff categories on a given WS
function getAvailAndTimeOffCategoriesOnWS (workspace) {
  const wsTimeOffCats = workspace && workspace.unavailabilityCategories ? workspace.unavailabilityCategories : []
  const ret = []

  // unavailabilities
  ret.push({
    id: null,
    available: false,
    type: 'SOFT',
    name: t('UNAV_UNAVAILABLE'),
    tag: null,
    archived: false,
    employeeCanRequest: true
  })

  // availabilities
  ret.push({
    id: null,
    available: true,
    type: 'SOFT',
    name: t('UNAV_UNAVAILABLE_AVAIL'),
    tag: null,
    archived: false,
    employeeCanRequest: true
  })

  // timeOffs
  wsTimeOffCats.forEach(cat => {
    ret.push({
      id: cat.id,
      type: 'HARD',
      name: (cat.title && cat.title !== '') ? cat.title : t('UNAV_CAT_NO_TITLE'),
      tag: (cat.tag && cat.tag !== '') ? cat.tag : t('UNAV_CAT_NO_TAG'),
      archived: cat.archived,
      employeeCanRequest: cat.employeeCanRequest,
      maxDailyMinutes: cat.maxDailyMinutes,
      maxYearlyDays: cat.maxYearlyDays,
      vacation: cat.vacation
    })
  })

  return ret
}

function getFilteredEvents (evts, calendarFilters, skipRowFilters = true) {
  return evts.map(evt => {
    let filtered = false
    for (var i = 0; i < calendarFilters.length && !filtered; i++) {
      const fil = calendarFilters[i]
      if (skipRowFilters) {
        if (fil.userId) continue // skip userId row filters here
        if (fil.peopleWithPositionId) continue // skip peopleWithPositionId row filters here
        if (fil.localityId) continue // skip localityId row filters here
        if (fil.labelData) continue // skip userId row filters here
        if (fil.hideWarnings) continue // ignore hideWarnings filter here - this is applied in set-calendar
      }

      // try Object-assigning the filter to an event - if it doesn't change it,
      // the event should be filtered
      const evtWithFilterApplied = miscUtil.mergeDeep(evt, (
        fil.data && !evt.data
          ? fil.data
          : fil))

      if (miscUtil.safeStringify(evtWithFilterApplied) === miscUtil.safeStringify(evt)) {
        filtered = true
        return null
      }
    }
    return evt
  }).filter(col => !!col)
}

function getSupportedShiftAttributes (workspace, shiftData = null) {
  if (!workspace) return []
  return workspace.shiftAttributes ? workspace.shiftAttributes : []
}

function getShiftAttributes (shiftData, workspace) {
  const attribs = []
  if (workspace && workspace.shiftAttributes && shiftData.customAttributes) {
    shiftData.customAttributes.map(att => {
      const wsAttrib = workspace.shiftAttributes.find(wsatt => wsatt.id === att.attributeId)
      if (wsAttrib) attribs.push(wsAttrib)
    })
  }
  return attribs
}

// returns an extra text that's displayed on calendar event after the position name (contains things like shift's location and custom attributes)
function getShiftMetaText (shiftData, workspace) {
  const ret = []

  const loc = shiftData.localityId && workspace.localities && workspace.localities.find(l => l.id === shiftData.localityId)
  if (loc) ret.push(loc.shortName)

  const attribs = getShiftAttributes(shiftData, workspace)
  if (attribs && attribs.length) {
    attribs.forEach(att => {
      ret.push(att.readOnly ? t(att.shortName) : att.shortName)
    })
  }

  return ret.length ? ret.join(', ') : null
}

// definition of icons belonging to specific attributes
const SHIFT_ATTRIBUTE_ICONS = [
  { shortName: 'HOM', icon: 'building' },
  { shortName: 'HO', icon: 'building' }
]

// returns the icon belonging to the given attribute
function getAttributeIcon (attribute) {
  let attrIcon = SHIFT_ATTRIBUTE_ICONS.find(sai => sai.id === attribute.id)
  if (!attrIcon) attrIcon = SHIFT_ATTRIBUTE_ICONS.find(sai => sai.shortName === attribute.shortName)
  if (!attrIcon) attrIcon = { icon: 'badge' }
  return attrIcon.icon
}

// returns the icon belonging to the given attribute
function getShiftAttributeIcon (shiftData, workspace) {
  const attribIcons = getShiftAttributes(shiftData, workspace).map(att => getAttributeIcon(att))
  if (attribIcons.length === 0) return null
  if (attribIcons.length === 1) return attribIcons[0]

  // priorities: first anything that's not 'badge', then badge
  const notTag = attribIcons.find(ico => ico !== 'badge')
  if (notTag) {
    return notTag
  } else {
    return 'badge'
  }
}

// checks if a shift (shiftData) matches one of the shiftTemplates on the WS and returns that template
function getShiftsTemplate (shiftData, workspace) {
  if (!workspace || !workspace.shiftTemplates || !workspace.shiftTemplates.length) return null

  const preprocessObj = (objPar) => {
    const obj = JSON.parse(JSON.stringify(objPar))
    if (obj.period && obj.period.start && moment(obj.period.start).isValid()) obj.period.start = moment(obj.period.start).clone().format('HH:mm')
    if (obj.period && obj.period.end && moment(obj.period.end).isValid()) obj.period.end = moment(obj.period.end).clone().format('HH:mm')
    if (isNaN(parseInt(obj.idealSkill))) {
      obj.idealSkill = null
    } else {
      obj.idealSkill = parseInt(obj.idealSkill)
    }
    if (!obj.pausesFixed) delete obj.pauses
    if (!obj.note || obj.note.trim() === '') {
      obj.note = null
    } else {
      obj.note = obj.note.trim()
    }
    if (!obj.standBy) obj.standBy = null
    if (!obj.overTime) obj.overTime = null
    return obj
  }

  for (var i = 0; i < workspace.shiftTemplates.length; i++) {
    const objTpl = workspace.shiftTemplates[i].shift
    if (JSON.stringify(Object.assign({}, preprocessObj(shiftData), preprocessObj(objTpl))) === JSON.stringify(preprocessObj(shiftData))) return workspace.shiftTemplates[i]
  }
  return null
}

// creates a new shift on selected WS according to passed shift template (requires the createShift function from /ac/create-shift.js to be passed as an argument)
function createShiftFromShiftTemplate (tpl, userId, day, createShiftFn, callbackFn) {
  console.log('createShiftFromShiftTemplate', tpl, userId, day, createShiftFn)
  createShiftFn(day, {
    user: userId,
    position: tpl.shift.positionId,
    locality: tpl.shift.localityId,
    idealSkill: tpl.shift.idealSkill,
    start: timeUtil.asMinutes(tpl.shift.period.start),
    end: timeUtil.asMinutes(tpl.shift.period.end),
    pauses: tpl.shift.pauses,
    pausesFixed: tpl.shift.pausesFixed,
    customAttributes: tpl.shift.customAttributes,
    note: tpl.shift.note,
    overTime: tpl.shift.overTime,
    standBy: tpl.shift.standBy,
    agenda: tpl.shift.agenda
  }).then(res => {
    if (callbackFn) callbackFn(res)
  })
}

// compute the correct height for the calendar sections
function getComputedCalendarSectionsHeight (calendar, overrides = {}) {
  let sectionsHeight = 'calc(100%' // start with 100% of calendar's height
  sectionsHeight += ' - 3.1875rem' // leave some space for the header
  if (calendar.displayCapacities) sectionsHeight += ' - ' + (overrides.capacitiesheight || capacitiesStyles.capacitiesheight) // leave some space for the capacities
  if (calendar.displayStatistics) sectionsHeight += ' - ' + (overrides.statsheight || statsStyles.statsheight) // leave some space for the stats
  if (calendar.displayRowTemplates && permissionsUtil.canWrite(PERMISSIONS.CALENDAR)) sectionsHeight += ' - ' + (overrides.templatesheight || templatesStyles.templatesheight) // leave some space for the shift templates
  sectionsHeight += ')'
  return sectionsHeight
}

// takes an event object that's either Availability or TimeOff and returns string 'availability', 'unavailability' or 'timeOff' accordingly
function isEventAvailabilityOrTimeOff (event) {
  return event.categoryId
    ? event.categoryId === 'availability' || event.categoryId === 'unavailability'
      ? event.categoryId
      : 'timeOff'
    : event.available
      ? 'availability'
      : 'unavailability'
}

const getShortestPeriodCoveringEvents = (events, addBufferDays = 1) => {
  let earliest = null
  let latest = null
  if (isValue(events)) {
    events.forEach(s => {
      if (!earliest || moment(earliest).isAfter(s.period?.start)) earliest = moment(s.period?.start)
      if (!latest || moment(latest).isBefore(s.period?.end)) latest = moment(s.period?.end)
    })
  }
  if (earliest && latest) return { start: earliest.add(-addBufferDays, 'day'), end: latest.add(addBufferDays, 'day') }
}

// returns true if 2 periods overlap, while accounting for the fact that some periods might have undefined start/end
const isPeriodsOverlap = (period1, period2) => {
  const p1end = period1?.end ? moment(period1?.end) : moment('9999-12-31', 'YYYY-MM-DD')
  const p2end = period2?.end ? moment(period2?.end) : moment('9999-12-31', 'YYYY-MM-DD')
  const p1start = period1?.start ? moment(period1?.start) : moment('1000-01-01', 'YYYY-MM-DD')
  const p2start = period2?.start ? moment(period2?.start) : moment('1000-01-01', 'YYYY-MM-DD')
  return p1end.isAfter(p2start) && p1start.isBefore(p2end)
}

// returns true if I have some kind of calendar access in a given workspace - this is
// used to determine if we're allowed to displayed a shift with this wsId in employee calendar and for some logic in view-shift-as-employee modal
function iHaveCalendarAccessInWorkspace (wsId, me) {
  return wsId &&
    Object.keys(me?.acl?.workspaces).includes(wsId) &&
    (me.acl.workspaces[wsId].includes(PERMISSIONS.EMP_CALENDAR + '_read') || me.acl.workspaces[wsId].includes(PERMISSIONS.EMP_DASHBOARD + '_read') || me.acl.workspaces[wsId].includes(PERMISSIONS.CALENDAR + '_read') ||
    me.acl.workspaces[wsId].includes(PERMISSIONS.EMP_CALENDAR + '_write') || me.acl.workspaces[wsId].includes(PERMISSIONS.EMP_DASHBOARD + '_write') || me.acl.workspaces[wsId].includes(PERMISSIONS.CALENDAR + '_write'))
}

// TODO / WIP
async function createShiftsForMonth (month, workspaceId) {
  const dispatch = store.dispatch
  let monthStart = moment(month, 'YYYY-MM').startOf('month')
  const monthEnd = moment(month, 'YYYY-MM').endOf('month')

  // if we're copying to current month, start creating shifts from tomorrow. never create shifts in the past
  if (monthStart.isBefore(moment())) monthStart = moment().add(1, 'day').startOf('day')
  if (monthStart.isAfter(monthEnd)) return false

  const periodStart = monthStart.clone().subtract(28, 'days')
  const periodEnd = monthEnd.clone().subtract(28, 'days')

  await dispatch(copyShiftPeriod({
    workspace: workspaceId,
    period: {
      start: periodStart,
      end: periodEnd
    },
    targetDates: [monthStart.format('YYYY-MM-DD')],
    copyAssign: true
  }))
}
window.createShiftsForMonth = createShiftsForMonth

export default {
  getTimeOffOrAvailCategory,
  getAvailAndTimeOffCategoriesOnWS,
  getAttributeIcon,
  getShiftAttributeIcon,
  getShiftMetaText,
  getShiftsTemplate,
  getReloadPeriod,
  getColIdentifier,
  getEvtIdentifier,
  getElementsColIdentifier,
  getElementsEvtIdentifier,
  getIdentifierSeparator,
  getFilteredEvents,
  getShortestPeriodCoveringEvents,
  getShiftAttributes,
  getSupportedShiftAttributes,
  getComputedCalendarSectionsHeight,
  isEventAvailabilityOrTimeOff,
  isPeriodsOverlap,
  iHaveCalendarAccessInWorkspace,
  createShiftFromShiftTemplate,
  createShiftsForMonth
}
