"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyntaxHighlightJSON = void 0;
const react_1 = __importStar(require("react"));
const keyStyle = {
    color: '#922',
};
const stringStyle = {
    color: '#080',
};
const numberStyle = {
    color: '#1c00cf',
};
const booleanStyle = {
    color: '#aa0d91',
};
const nullStyle = {
    color: '#808080',
};
const jsonStyle = {
    backgroundColor: '#f6f8fa',
    padding: '10px',
    borderRadius: '4px',
    overflowY: 'auto',
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    height: '100%',
    fontSize: '0.9rem',
};
const JSONNode = ({ data, name, depth, indentSize }) => {
    const [collapsed, setCollapsed] = (0, react_1.useState)(false);
    const toggleCollapse = () => setCollapsed(!collapsed);
    const indent = depth * indentSize;
    if (typeof data === 'object' && data !== null) {
        const isArray = Array.isArray(data);
        const entries = isArray ? data : Object.entries(data);
        const openingBracket = isArray ? '[' : '{';
        const closingBracket = isArray ? ']' : '}';
        return (react_1.default.createElement("div", { style: { paddingLeft: indent } },
            react_1.default.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                name !== undefined && (react_1.default.createElement("span", { style: keyStyle },
                    "\"",
                    name,
                    "\": ")),
                entries.length > 0 ? (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("span", { onClick: toggleCollapse, style: { cursor: 'pointer', color: '#aaa', marginRight: 4 } }, collapsed ? '[+]' : '[-]'),
                    react_1.default.createElement("span", null, openingBracket))) : (react_1.default.createElement("span", null, isArray ? '[]' : '{}'))),
            !collapsed && entries.length > 0 && (react_1.default.createElement("div", null,
                isArray
                    ? data.map((item, index) => (react_1.default.createElement(JSONNode, { key: index, data: item, depth: depth + 1, indentSize: indentSize })))
                    : Object.entries(data).map(([key, value], index) => (react_1.default.createElement(JSONNode, { key: index, name: key, data: value, depth: depth + 1, indentSize: indentSize }))),
                react_1.default.createElement("div", { style: { paddingLeft: indent } },
                    react_1.default.createElement("span", null, closingBracket))))));
    }
    let style = numberStyle;
    if (typeof data === 'string') {
        style = stringStyle;
    }
    else if (typeof data === 'boolean') {
        style = booleanStyle;
    }
    else if (data === null) {
        style = nullStyle;
    }
    return (react_1.default.createElement("div", { style: { paddingLeft: indent } },
        name !== undefined && (react_1.default.createElement("span", { style: keyStyle },
            "\"",
            name,
            "\": ")),
        react_1.default.createElement("span", { style: style }, typeof data === 'string' ? `"${data}"` : String(data)),
        react_1.default.createElement("span", null, ",")));
};
const SyntaxHighlightJSON = ({ json, indentSize = 4 }) => {
    if (!json)
        return react_1.default.createElement("span", null);
    let parsed;
    try {
        parsed = JSON.parse(json);
    }
    catch (_a) {
        return react_1.default.createElement("pre", { style: jsonStyle }, "Invalid JSON");
    }
    return (react_1.default.createElement("pre", { style: jsonStyle },
        react_1.default.createElement(JSONNode, { data: parsed, depth: 0, indentSize: indentSize })));
};
exports.SyntaxHighlightJSON = SyntaxHighlightJSON;
