"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.detectOverlaps = void 0;
const util_1 = require("@app/util");
const hasDefinedStartAndDuration = (t) => (0, util_1.isValue)(t.start) && (0, util_1.isValue)(t.duration);
const detectOverlaps = (period) => {
    if (!(0, util_1.isValue)(period))
        return [];
    // Map each pause to include its original index
    const periodsWithIndex = period
        .filter(hasDefinedStartAndDuration)
        .map((_period, index) => (Object.assign(Object.assign({}, _period), { originalIndex: index })));
    // Sort the pauses by their start time
    periodsWithIndex.sort((a, b) => a.start - b.start);
    const overlaps = [];
    for (let i = 1; i < periodsWithIndex.length; i++) {
        const current = periodsWithIndex[i];
        const previous = periodsWithIndex[i - 1];
        const previousEnd = previous.start + previous.duration;
        if (current.start < previousEnd) {
            overlaps.push({
                firstOverlapIndex: previous.originalIndex,
                secondOverlapIndex: current.originalIndex,
                firstOverlap: previous,
                secondOverlap: current,
            });
        }
    }
    return overlaps;
};
exports.detectOverlaps = detectOverlaps;
