
import requestRoleStatsMulti from '@app/request/role-stats-multi'
import isLoading from './is-loading'
import addError from '../ac/add-error'
import setCalendar from '../action/set-calendar'
import store from '../store'
import { miscUtil } from '@app/util'
import { SORT_BY, getSortedBy } from '@app/component/ui/calendar/common/sort-by'

export default (date, targets = undefined) => {
  return async (dispatch) => {
    const { auth, workspaceId } = store.getState()
    let { loading } = store.getState()
    if (!workspaceId) return

    const sortedBy = getSortedBy()

    const splitByContractTypes = sortedBy === SORT_BY.CONTRACT
    const splitByPositions = sortedBy === SORT_BY.POSITION

    // if load-role-stats is already running, try waiting for it to finish first
    if (loading.includes('load-role-stats-multi')) {
      let w = 0
      const maxW = 60
      while (loading.includes('load-role-stats-multi') && w < maxW) {
        w++
        loading = store.getState().loading
        await new Promise((resolve, reject) => setTimeout(resolve, 250))
      }
      if (w < maxW) return null
    }

    // Only reload role stats if we're on /schedule page (looking at the manager's calendar) or if we have EmployeeCard modal open.
    // Otherwise, reset 'store.calendar.roleStats' to undefined, so it's loaded again when it's needed.
    // We want to avoid unnecessary calls to requestRoleStatsMulti() when they're not 100% needed, because it's really slow and it slows down the BE.
    let roleStatsAreNeededNow = false
    if (!roleStatsAreNeededNow && window.location.pathname.includes('/schedule')) roleStatsAreNeededNow = true
    if (!roleStatsAreNeededNow && store.getState()?.modal?.type === 'employee-profile') roleStatsAreNeededNow = true

    if (!roleStatsAreNeededNow) {
      const cal = store.getState().calendar
      if (cal) {
        await dispatch(setCalendar(Object.assign({}, cal, {
          roleStats: undefined
        })))
      }
      return false
    }

    await dispatch(isLoading('load-role-stats-multi'))

    // load the stats from the API
    const enforcedLocality = miscUtil.getEnforcedLocality()
    const result = await requestRoleStatsMulti({
      id: workspaceId,
      referenceDate: date,
      userIds: targets || (enforcedLocality ? Object.keys(store.getState().employees) : undefined),
      splitByContractTypes,
      splitByPositions
    }, auth)

    if (result.error) {
      dispatch(addError(result.error))
      dispatch(isLoading('load-role-stats-multi', true))
      return
    }

    // save the loaded role stats to the store as store.calendar.roleStats
    const newRoleStats = {}
    const newVacations = {}
    result.members.forEach(m => {
      if (!m.roles || !m.roles.length) return null
      newRoleStats[m.roles[0].userId] = m.roles[0].stats
      newVacations[m.roles[0].userId] = m.roles[0].vacation
    })

    const { calendar } = store.getState()
    await dispatch(setCalendar(Object.assign({}, calendar, {
      roleStats: (targets && calendar?.date === date)
        ? Object.assign({}, calendar.roleStats || {}, newRoleStats)
        : newRoleStats,
      vacations: (targets && calendar?.date === date)
        ? Object.assign({}, calendar.vacations, newVacations)
        : newVacations
    })))

    await dispatch(isLoading('load-role-stats-multi', true))
  }
}
