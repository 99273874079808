import React, { Fragment } from 'react'
import { t } from 'i18next'
import moment from 'moment'

import {
  Input,
  Button,
  ColorPicker,
  Modal
} from '@ui'
import { Shifts } from '@core'
import { miscUtil, timeUtil } from '@app/util'

import connect from './connect'
import './index.scss'

// UNUSED
class TemplateCreate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      form: {
        name: null,
        color: null
      }
    }
    this.handleOnSubmit = this._onSubmit.bind(this)
  }

  // turn the shifts into a set of requirement, joining the shifts with the same poition+start+duration+skill
  getReqsFromShifts (shifts) {
    const result = []
    shifts.forEach((sf) => {
      if (!sf.start) sf.start = timeUtil.periodToStart(sf.period)
      if (!sf.duration) sf.duration = timeUtil.periodToDuration(sf.period)

      let idx = result.findIndex((s) => {
        if (s.positionId !== sf.positionId) return false
        if (s.start !== sf.start) return false
        if (s.duration !== sf.duration) return false
        if (s.idealSkill !== sf.idealSkill) return false
        if (s.pausesFixed !== sf.pausesFixed) return false
        if (
          miscUtil.safeStringify(s.pauses) !== miscUtil.safeStringify(sf.pauses)
        ) { return false }
        return true
      })
      if (idx < 0) {
        result.push(Object.assign({}, sf, { reqIds: [] }))
        idx = result.length - 1
      }
      result[idx].reqIds.push(sf.id || null)
    })
    return result
  }

  _onSubmit () {
    this.props.saveDayAsTemplate(this.state.form, this.getDayShifts())
    this.props.setModal(null)
  }

  getDayShifts () {
    const { date, shifts } = this.props
    if (!date || !shifts) return []
    return this.getReqsFromShifts(
      shifts.filter(
        (s) =>
          s.period &&
          s.period.start &&
          moment(s.period.start).format('YYYY-MM-DD') ===
            moment(date).format('YYYY-MM-DD')
      )
    )
  }

  render () {
    const { form } = this.state
    const { setModal, isLoading, date, shifts } = this.props

    if (!date || !shifts) return null
    const dayShifts = this.getDayShifts()
    if (!dayShifts) return null

    return (
      <Modal
        className='ds-template-create'
        size={Modal.SIZES.L}
        isLoading={isLoading}
        headerContent={
          <div className='ds-title'>
            {t('SAVE_DAY_AS_TEMPLATE')}
          </div>
        }
        sections={[
          <Fragment key={0}>
            <div className='ds-mtc-col-left' style={{ alignItems: 'center' }}>
              {t('NAME')}
            </div>
            <div className='ds-mtc-col-right'>
              <Input
                type='text'
                autoFocus
                value={form.name}
                placeholder={t('SAVE_DAY_AS_TEMPLATE_NAME_PLACEHOLDER')}
                onChange={(val) => { this.setState((s) => Object.assign({}, s, { form: { name: val, color: form.color } })) }}
                onSubmit={this.handleOnSubmit}
              />
            </div>
          </Fragment>,
          <Fragment key={1}>
            <div className='ds-mtc-col-left'>{t('COLOR')}</div>
            <div className='ds-mtc-col-right'>
              <ColorPicker
                value={form.color}
                onChange={(value) => { this.setState((s) => Object.assign({}, s, { form: { color: value, name: form.name } })) }}
              />
            </div>
          </Fragment>,
          <Fragment key={2}>
            <div className='ds-mtc-col-left'>{t('PREVIEW')}</div>
            <div className='ds-mtc-col-right'>
              <Shifts readOnly sm hideShiftsCountSection shifts={dayShifts} />
            </div>
          </Fragment>
        ]}
        footerContent={
          <>
            <Button
              label={t('CLOSE')}
              size={Button.SIZES.LARGE}
              onClick={() => setModal(null)}
            />
            <Button
              size={Button.SIZES.LARGE}
              style={Button.STYLES.CONTAINED}
              color={Button.COLORS.GREEN}
              label={t('SAVE')}
              disabled={isLoading || !form || !form.name}
              loading={isLoading}
              onClick={this.handleOnSubmit}
            />
          </>
        }
      />
    )
  }
}

export default connect(TemplateCreate)
