/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react'
import { t } from 'i18next'
import moment from 'moment'

import {
  Button,
  Input,
  Tooltip,
  Icon,
  Position,
  ShiftAttribute,
  Dropdown,
  Checkbox,
  Text,
  Modal,
  Switcher,
  SkillSelect,
  Flex,
  Spacing,
  HelpTooltip,
  Alert
} from '@ui'
import {
  numberUtil,
  miscUtil,
  calendarUtil,
  multiSelectUtil,
  sortUtil,
  propsUtil,
  timeUtil,
  permissionsUtil,
  isUndefined,
  isNull
} from '@app/util'
import { PERMISSIONS } from '@app/const'
import { requestOrganizationTransferCreate } from '@app/request'
import requestShiftDummyWarningsAndData from '@app/request/shift-dummy-warnings-and-data'

import './index.scss'
import connect from './connect'
import {
  ContractTypes,
  OverTime,
  StandBy,
  Agenda,
  ShiftTemplates,
  TransferCreation
} from './form-sections'
import { formatHours } from '@app/util/format-hours'
import { DetailedStatistics } from '../shift-1.5/sections/detailed-statistics'
import { withUseDevTestingCurrentShiftModal } from '../shift-1.5/util/dev-testing-current-shift-modal'

class EditShift extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      detailsState: {},
      errors: [],
      newTemplateName: null,
      transferOriginWorkspaceEmps: null,
      isLoadingDummyData: false,
      isSaving: false,
      shouldAutoFocus: null,
      showDetailedStats: false
    }
    this.saveShift = this._saveShift.bind(this)
    this.deleteShift = this._deleteShift.bind(this)
    this.canSave = this._canSave.bind(this)
    this.loadDetails = this._loadDetails.bind(this)
    this.setVal = this._setVal.bind(this)
    this.getDefaultDetails = this._getDefaultDetails.bind(this)
    this.selectNewestPosition = this._selectNewestPosition.bind(this)
    this.selectFirstEmployee = this._selectFirstEmployee.bind(this)
    this.refreshWarnings = this._refreshWarnings.bind(this)
    this.applyTemplate = this._applyTemplate.bind(this)
    this.getDetailsObject = this._getDetailsObject.bind(this)
    this.getSelectedContractId = this._getSelectedContractId.bind(this)

    this.enforcedLocality = miscUtil.getEnforcedLocality()

    // use the default details if creating a new shift or shiftTemplate
    if (props.newShift || props.newShiftTemplate) {
      this.state.detailsState = this.getDefaultDetails(
        props.day,
        props.hour,
        props.hourDuration,
        props.userId,
        props.positionId,
        props.localityId,
        props.pauses,
        props.pausesFixed,
        props.idealSkill,
        props.note,
        props.overTime,
        props.standBy,
        props.standByActivities,
        props.customAttributes,
        props.contractType,
        props.contractId,
        props.agenda,
        props.createTransferFrom,
        props.createTransferTo
      )
      this.refreshWarnings(true)
    }
  }

  componentDidMount () {
    // load WS & positions if it's not yet loaded
    if (!this.props.workspace) this.props.loadWorkspaceDetail(this.props.workspaceId)
    if (!this.props.positions || !this.props.positions.length) this.props.loadPositions(this.props.workspaceId)
    // reset the state if creating a new shift
    if (this.props.newShift) {
      this.setState((s) => Object.assign({}, s, {
        isSaving: false,
        detailsState: this.getDefaultDetails(
          this.props.day,
          this.props.hour,
          this.props.hourDuration,
          this.props.userId,
          this.props.positionId,
          this.props.localityId,
          this.props.pauses,
          this.props.pausesFixed,
          this.props.idealSkill,
          this.props.note,
          this.props.overTime,
          this.props.standBy,
          this.props.standByActivities,
          this.props.customAttributes,
          this.props.createTransferFrom,
          this.props.createTransferTo
        )
      }))
    }
    // load the shiftDetails if not creating a new one
    if (!this.props.newShift && !this.props.newShiftTemplate) {
      this.loadDetails()
    }
    // initialize the state, if initialState prop was passed
    if (this.props.initialState) {
      this.setState((s) => Object.assign({}, this.props.initialState))
      setTimeout(() => {
        this.refreshWarnings()
      }, 500)
    }
  }

  componentDidUpdate () {
    const { shiftDetail, newShift, type } = this.props
    if (!shiftDetail && !newShift && type !== 'template' && type !== 'shiftTemplate' && type !== 'cycle') this.loadDetails()
  }

  _getSelectedContractId (details, employee) {
    if (details?.contractId) return details.contractId
    if (this.props.newShift && Array.isArray(employee?.contracts) && details?.period?.start) {
      const relevantContracts = employee.contracts
        .filter((contract) => contract.period && (
          moment(contract.period.start).isBefore(moment(details?.period?.start)) || !contract.period.start
        ) && (
          moment(contract.period.end).isAfter(moment(details?.period?.start)) || !contract.period.end
        ))
      if (relevantContracts.length) return relevantContracts[0].id
    }
    return null
  }

  _refreshWarnings (calledFromConstructor = false) {
    const { auth, workspaceId, employees, shiftDetail } = this.props
    const d = Object.assign({}, shiftDetail, this.state.detailsState)

    if (!d.userId) return
    if (!d.positionId) return

    if (!calledFromConstructor) {
      this.setState(s => Object.assign({}, s, { isLoadingDummyData: true }))
    } else {
      this.state.isLoadingDummyData = true
    }

    requestShiftDummyWarningsAndData({
      workspaceId,
      id: d.id,
      data: {
        period: d.period,
        user: d.userId,
        position: d.positionId,
        contractId: this.getSelectedContractId(d, employees[d.userId]),
        idealSkill: (typeof d.idealSkill !== 'undefined' && !isNaN(parseInt(d.idealSkill))) ? parseInt(d.idealSkill) : null,
        pauses: d.pauses,
        pausesFixed: d.pausesFixed,
        customAttributes: d.customAttributes,
        overTime: d.overTime,
        standBy: d.standBy,
        standByActivities: d.standByActivities,
        agenda: (d.agenda || []).map(ag => { return Object.assign({}, ag, { name: ag.name || '' }) } /* just making sure that agenda's 'name' prop is set before sending to API */)
      }
    }, auth).then((res) => {
      const newDs = this.state.detailsState
      if (res && !res.error) {
        newDs.warnings = res?.warnings?.filter(w =>
          !(d.id && w.name === 'busy' && w?.data?.shiftId === d.id) && // ignore the warning saying that a potential dummy shift intersects with the same shift we're currently editing
          !(d.id && w.name === 'eventConflict' && w?.data?.eventId && d.standByActivities?.length && d.standByActivities?.includes(w.data.eventId)) // ignore the warning saying that a potential dummy shift intersects with one of its standByActivities
        ) || []
        newDs.automaticPauses = res?.pauses || []
        newDs.pauses = res?.pauses || newDs.pauses
        newDs.stats = res?.stats || []
        if (!newDs.contractId && res?.contractId) newDs.contractId = res?.contractId
      }
      this.setState((s) => Object.assign({}, s, { isLoadingDummyData: false, detailsState: newDs }))
    })
  }

  _getDefaultDetails (
    day,
    hour,
    hourDuration,
    userId,
    positionId,
    localityId,
    pauses,
    pausesFixed,
    idealSkill,
    note,
    overTime,
    standBy,
    standByActivities,
    customAttributes,
    contractType,
    contractId,
    agenda
  ) {
    const { type, workspaceId, positions, calendar, employees } = this.props

    const dayMoment = day && day !== 'day-template' ? moment(day) : moment().startOf('day')
    const hStart = (typeof hour !== 'number' && calendar.view !== 'day')
      ? 8
      : Math.floor(hour)
    const mStart = hour
      ? Math.round((hour % 1) * 60)
      : 0
    const hEnd = (typeof hour !== 'number' && calendar.view !== 'day')
      ? 16
      : Math.floor(hour + (hourDuration || 8)) % 24
    const mEnd = (hour && hourDuration)
      ? Math.round(((hour + (hourDuration || 8)) % 1) * 60)
      : 30

    // auto-select the initial position using some reasonable logic
    let selectedPositionId = positionId
    if (!positionId && type === 'live') {
      // sort the positions by number of assigned people
      let positionsOnWS = [...(Array.isArray(positions) ? positions : [])].filter(p => !p.archived).map(p => { return { ...p, employeesCount: 0 } })
      Object.values(employees).forEach(ee => {
        (ee.positions || []).forEach(p => {
          const idx = positionsOnWS.findIndex(pow => pow.id === p.id)
          positionsOnWS[idx].employeesCount = positionsOnWS[idx].employeesCount + 1
        })
      })
      positionsOnWS = positionsOnWS.sort((a, b) => { return a.employeesCount < b.employeesCount ? 1 : -1 })

      if (userId) {
        // if the shift is assigned to someone, pick one position that is assigned to that user (this will prioritize the position that's assigned to most other people, since we sorted that array before)
        if (Object.keys(employees).includes(userId) && employees[userId]?.positions?.length) {
          const p = positionsOnWS.find(p => employees[userId].positions.some(eePos => eePos.id === p.id))
          if (p) selectedPositionId = p.id
        }

        // if we still don't have a pick by now, it means the user doesn't have any position, in which case we just pick the most assigned position on the WS, even though the user doesn't have it
        if (!selectedPositionId) {
          if (positionsOnWS?.length) selectedPositionId = positionsOnWS[0].id
        }
      } else {
        // if the shift is unassigned, just pick the most used position on WS
        if (positionsOnWS?.length) selectedPositionId = positionsOnWS[0].id
      }
    }

    return {
      id: null,
      userId,
      positionId: selectedPositionId,
      localityId: this.enforcedLocality
        ? this.enforcedLocality // if this manager has enforced locality, use that one when creating new shifts
        : (localityId || null),
      idealSkill,
      period: {
        start: dayMoment.clone().set({ hour: hStart, minute: mStart }).format(),
        end: dayMoment.clone().set({ hour: hEnd, minute: mEnd }).format()
      },
      pauses: pauses || [],
      customAttributes: customAttributes || [],
      pausesFixed: pausesFixed || false,
      warnings: [],
      note,
      overTime: overTime || null,
      standBy: standBy || false,
      standByActivities: standByActivities || [],
      copiesCount: 1,
      contractType: contractType || null,
      contractId: contractId || null,
      agenda: agenda || null,
      createTransferFrom: null,
      createTransferTo: workspaceId
    }
  }

  async _saveShift () {
    const {
      type, templateId, updateLiveShift, updateTemplateShift,
      shiftDetail, newShift, createShift,
      addTemplateShift, templateAddToRequirementsArrayIdx,
      auth, setModal, organizationId,
      setCycleDetail, colId, groupId, eventId, updateCycleDetail
    } = this.props
    console.log(this.props)
    let detailsState = this.state.detailsState

    const stateUpdate = {}
    stateUpdate.isSaving = true

    // if an 'afterSubmit' function was provided, we'll call it here
    const afterSubmit = this.props.afterSubmit ? this.props.afterSubmit : (res) => { }

    // fix the period: if the end is before the start in newly created shift
    if (detailsState && detailsState.period && detailsState.period.start && detailsState.period.end) {
      if (moment(detailsState.period.start).isAfter(moment(detailsState.period.end))) {
        const newDs = detailsState
        newDs.period.end = moment(newDs.period.end).add(1, 'days').format()
        detailsState = newDs
        stateUpdate.detailsState = newDs
      }
    }

    // fix the pauses: remove the pauses that are outside the shift's bounds
    if (!newShift && shiftDetail && shiftDetail.pauses && shiftDetail.pauses.length && detailsState && detailsState.duration && !detailsState.pauses) {
      const outOfBoundsPause = shiftDetail.pauses.find(p => p.start + p.duration > detailsState.duration)
      if (outOfBoundsPause) {
        detailsState.pauses = shiftDetail.pauses.filter(p => p.start + p.duration <= detailsState.duration)
        stateUpdate.detailsState = detailsState
      }
    }

    this.setState((s) => Object.assign({}, s, stateUpdate))

    // shift modification / creation
    if (!newShift) {
      // update an existing shift
      if (type === 'live' && shiftDetail) {
        const res = await updateLiveShift(shiftDetail.id, detailsState)
        setModal(null)
        afterSubmit(res)
      }
      if (type === 'template') {
        await updateTemplateShift(templateId, detailsState.id, detailsState).then((res) => {
          setModal(null)
          afterSubmit(res)
        })
      }
      if (type === 'cycle') {
        const shiftStart = detailsState.start
        let shiftEnd = detailsState.end
        if (shiftEnd <= shiftStart) {
          shiftEnd = shiftEnd + 1440
        }
        const updatedCycle = {
          data: {
            start: shiftStart,
            end: shiftEnd,
            duration: Math.round(shiftEnd - shiftStart),
            positionId: detailsState.positionId,
            localityId: detailsState.localityId,
            idealSkill: detailsState.idealSkill,
            employees: detailsState.copiesCount,
            pauses: detailsState.pauses,
            pausesFixed: detailsState.pausesFixed,
            overTime: detailsState.overTime,
            standBy: detailsState.standBy,
            agenda: detailsState.agenda,
            note: detailsState.note,
            period: detailsState.period,
            customAttributes: detailsState.customAttributes,
            id: detailsState.id
          },
          type: 'shift'
        }
        await updateCycleDetail(updatedCycle)
        setModal(null)
        afterSubmit(updatedCycle)
      }
    } else {
      // add a new shift
      const user = detailsState.userId
      const position = detailsState.positionId
      const locality = detailsState.localityId
      const idealSkill = (typeof detailsState.idealSkill !== 'undefined' && !isNaN(parseInt(detailsState.idealSkill))) ? parseInt(detailsState.idealSkill) : null
      const start = timeUtil.asMinutes(detailsState.period.start)
      const end = timeUtil.asMinutes(detailsState.period.end)
      const fixedDay = moment(detailsState.period.start).format('YYYY-MM-DD')
      const pausesFixed = detailsState.pausesFixed
      const pauses = pausesFixed ? detailsState.pauses : []
      const customAttributes = detailsState.customAttributes
      const note = detailsState.note
      const overTime = detailsState.overTime
      const standBy = detailsState.standBy
      const standByActivities = detailsState.standByActivities
      const newCopiesCount = (detailsState.copiesCount && parseInt(detailsState.copiesCount) > 1) ? parseInt(detailsState.copiesCount) : 1
      const contractType = detailsState.contractType
      const contractId = detailsState.contractId
      const agenda = detailsState.agenda

      console.log({ detailsState })

      if (type === 'live') {
        await createShift(fixedDay, {
          user,
          start,
          end,
          position,
          locality,
          idealSkill,
          employees: newCopiesCount,
          pauses,
          pausesFixed,
          customAttributes,
          note,
          overTime,
          standBy,
          standByActivities,
          contractType,
          contractId,
          agenda
        }).then((res) => {
          setModal(null)
          afterSubmit(res)
        })
      }
      if (type === 'template') {
        const fixedEnd = end > start ? end : (end + 1440)
        let dur = Math.abs(fixedEnd - start)
        if (dur === 0) dur = 1440
        await addTemplateShift(templateId, {
          start,
          duration: dur,
          positionId: position,
          localityId: locality,
          idealSkill,
          employees: newCopiesCount,
          pauses,
          pausesFixed,
          agenda,
          customAttributes,
          requirementsArrayIndex: templateAddToRequirementsArrayIdx || undefined
        }).then((res) => {
          setModal(null)
          afterSubmit(res)
        })
      }
      if (type === 'cycle') {
        const shiftStart = start + (colId * 1440)
        let shiftEnd = end + (colId * 1440)
        while (shiftEnd <= shiftStart) {
          shiftEnd = shiftEnd + 1440
        }
        const updatedCycle = {
          data: {
            start: shiftStart,
            end: shiftEnd,
            duration: Math.abs(shiftEnd - shiftStart),
            positionId: position,
            localityId: locality,
            idealSkill,
            employees: newCopiesCount,
            pauses,
            overTime,
            standBy,
            agenda,
            note,
            pausesFixed,
            customAttributes,
            period: detailsState.period,
            id: groupId + '_' + colId + '_' + eventId
          },
          type: 'shift'
        }
        await setCycleDetail(updatedCycle)
        setModal(null)
        afterSubmit(updatedCycle)
      }
      if (type === 'transfer') {
        setModal(null)
        const createdTransferShift = await requestOrganizationTransferCreate({
          organization: organizationId,
          fromWorkspace: detailsState.createTransferFrom,
          toWorkspace: detailsState.createTransferTo,
          data: {
            user,
            period: detailsState.period,
            position,
            locality,
            idealSkill,
            pauses,
            pausesFixed,
            overTime,
            standBy,
            standByActivities,
            customAttributes,
            note,
            contractType,
            contractId,
            agenda
          }
        }, auth)
        afterSubmit(createdTransferShift)
      }
    }

    this.setState((s) => Object.assign({}, s, { isSaving: false }))
  }

  _canSave () {
    const { newTemplateName, isSaving } = this.state
    const { workspace, isPluginEnabled } = this.props
    const details = this.getDetailsObject()

    if (isSaving) return false
    if (!details) return false

    if (this.props.newShift) {
      if (!details.positionId) return false
      if (!details.period || !details.period.start || !details.period.end) return false
      if (this.props.newAttendance && !details.userId) return false
      if (this.props.type === 'transfer' && (!details.userId || !details.createTransferFrom || !details.createTransferTo || details.createTransferFrom === details.createTransferTo)) return false
    }

    if (this.props.type === 'shiftTemplate') {
      if (!details.positionId) return false
      if (!details.period || !details.period.start || !details.period.end) return false
      if (!newTemplateName || newTemplateName.trim() === '') return false
    }

    if (Array.isArray(details.agenda)) {
      const noName = (ag) => !ag.name || ag.name.trim() === ''
      const noPosition = (ag) => isUndefined(ag.positionId) || isNull(ag.positionId)
      const noTag = (ag) => isUndefined(ag.customAttributes) || isNull(ag.customAttributes) || ag.customAttributes.length === 0

      if (details.agenda.some(ag => noName(ag) && noPosition(ag) && noTag(ag))) return false
    }

    // can't save if there is an agenda task without a name
    // if (details.agenda && details.agenda.length) {
    //   if (details.agenda.some(ag => !ag.name || ag.name.trim() === '')) return false
    // }

    // can't save if the shift has a custom attribute and one of the agenda task also has some custom attribute
    if (details.agenda && details.agenda.length && this.props.workspace) {
      const shiftAttribs = calendarUtil.getShiftAttributes(details, this.props.workspace)
      const someAgendaPointHasAttributes = details.agenda.some(ag => ag.special || (ag.customAttributes && ag.customAttributes.length))
      if (shiftAttribs.length && someAgendaPointHasAttributes) return false
    }

    if (!isPluginEnabled('reservio')) { // some agenda validity checks are skipped when WS uses Reservio plugin, since that plugin sets agenda tasks all over the place and it would prevent saving anything
      // can't save if some agenda tasks overlap each other
      if (details.agenda && details.agenda.length) {
        if (details.agenda.some((t1, i1) => details.agenda.some((t2, i2) => i2 !== i1 && t1.start < (t2.start + t2.duration) && (t1.start + t1.duration) > t2.start) /* task t1 overlaps with some other task t2 */)) {
          return false
        }
      }

      // can't save if some agenda tasks overlap some shift pauses
      if (details.agenda && details.agenda.length && details.pauses && details.pauses.length) {
        if (details.agenda.some(t => details.pauses.some(p => t.start < (p.start + p.duration) && (t.start + t.duration) > p.start) /* task t overlaps with some pause p */)) {
          return false
        }
      }
    }

    // if 'laborlaw' plugin's 'options.preventSavingShiftsWithWarnings' is True and there are some warnings displayed, prevent saving (unless we're in DS PRO)
    if (isPluginEnabled('laborlaw') && !miscUtil.isDayswapsProInterface()) {
      const pl = workspace?.plugins?.find(p => p.plugin === 'laborlaw')
      if (pl.options?.preventSavingShiftsWithWarnings) {
        if (details?.warnings?.length) {
          return false
        }
      }
    }

    return true
  }

  _deleteShift (details) {
    const {
      type,
      templateId,
      templates,
      deleteLiveShift,
      deleteTemplateShift,
      setModal
    } = this.props

    setModal('confirm', {
      title: t('MULTI_ACTION_DEL_CONFIRM_TITLE'),
      type: Alert.TYPES.ERROR,
      confirmLabel: t('DELETE'),
      onConfirm: async () => {
        // if an 'afterSubmit' function was provided, we'll call it here
        const afterSubmit = this.props.afterSubmit ? this.props.afterSubmit : (res) => { }

        const d = moment(details.period.start).format('YYYY-MM-DD')
        this.setState((s) => Object.assign({}, { detailsState: this.getDefaultDetails(d, null, null) }))

        if (type === 'live') {
          deleteLiveShift(details.id).then(res => { afterSubmit(res) })
        }
        if (type === 'template') {
          const ts = templates.find((t) => t.id === templateId).requirements.find((s) => s.hash === details.id)
          deleteTemplateShift(templateId, ts).then(res => { afterSubmit(res) })
        }

        multiSelectUtil.multiSelectClear()

        setModal(null)
      },
      cancelLabel: t('CANCEL'),
      onCancel: () => setModal(null),
      subtitle: t('MULTI_ACTION_DEL_CONFIRM_DELETE_SUBTITLE_X', { x: 1 }),
      buttonColor: 'red',
      overSidebar: true
    })
  }

  _loadDetails () {
    const {
      type,
      shift,
      loadShiftDetail,
      workspace,
      shiftTemplateId,
      newShiftTemplate,
      templateId,
      shiftHash,
      cycleDetail
    } = this.props
    if (!type || (!shift && !shiftTemplateId && !templateId && !shiftHash)) return
    if (type === 'live') {
      // load shift details from the BE
      loadShiftDetail(shift, false, false)
    }
    if (type === 'template') {
      const tempReqDetail = propsUtil.getShift(this.props)
      this.setState((s) => Object.assign({}, {
        detailsState: {
          id: tempReqDetail.hash,
          positionId: tempReqDetail.positionId,
          localityId: tempReqDetail.localityId,
          start: tempReqDetail.start,
          duration: tempReqDetail.duration,
          idealSkill: tempReqDetail.idealSkill,
          pauses: tempReqDetail.pauses,
          pausesFixed: tempReqDetail.pausesFixed,
          customAttributes: tempReqDetail.customAttributes,
          agenda: tempReqDetail.agenda
        }
      }))
    }
    if (type === 'cycle') {
      const detail = cycleDetail.data.find(cyc => cyc.data.id === shift.id)?.data
      if (detail) {
        this.setState((s) => Object.assign({}, {
          detailsState: {
            id: detail.id,
            positionId: detail.positionId,
            localityId: detail.localityId,
            start: detail.start,
            end: detail.end,
            duration: detail.duration,
            copiesCount: detail.employees,
            idealSkill: detail.idealSkill,
            pauses: detail.pauses,
            pausesFixed: detail.pausesFixed,
            note: detail.note,
            overTime: detail.overTime,
            standBy: detail.standBy,
            standByActivities: detail.standByActivities,
            agenda: detail.agenda,
            customAttributes: detail.customAttributes
          }
        }))
      }
    }
    if (type === 'shiftTemplate') {
      const sTmp = shiftTemplateId && !newShiftTemplate && workspace && workspace.shiftTemplates && workspace.shiftTemplates.find(st => st.id === shiftTemplateId)
      if (sTmp) {
        this.setState((s) => Object.assign({}, {
          detailsState: Object.assign({}, sTmp.shift),
          newTemplateName: sTmp.name
        }))
      }
    }
  }

  _setVal (key, val, shouldAutoFocus = undefined) {
    const { type, colId, newShift } = this.props
    this.setState(prevState => {
      // when changing period, make sure that the end is after the start, because the values
      // we get from the input elements are weird sometimes
      if (key === 'period' && moment(val.start).isValid() && moment(val.end).isValid() && !moment(val.start).isBefore(val.end)) {
        val.end = moment(val.end).clone().add(1, 'day')
      }

      // when changing period, make sure that the shift is not longer than 24 hours, because
      // we sometimes get weird values from the input elements
      if (key === 'period' && moment(val.start).isValid() && moment(val.end).isValid() && moment(val.end).isAfter(moment(val.start).clone().add(24, 'hours'))) {
        val.end = moment(val.end).clone().add(-1, 'day')
      }

      const detailsState = Object.assign({}, prevState.detailsState)
      detailsState[key] = val

      // when changing period, mirror the new value into the start, end & duration variables,
      // which are used in the plans and templates
      if (key === 'period') {
        const st = moment(detailsState.period.start)
        const en = moment(detailsState.period.end)
        if (st && st.isValid() && en && en.isValid()) {
          if (type === 'cycle') {
            let day = null
            if (newShift) {
              day = colId
            } else {
              day = parseInt(detailsState.id.split('_')[1])
            }
            detailsState.start = timeUtil.periodToStart(detailsState.period) + (1440 * day)
            detailsState.end = timeUtil.periodToEnd(detailsState.period) + (1440 * day)
          } else {
            detailsState.start = timeUtil.periodToStart(detailsState.period)
            detailsState.end = timeUtil.periodToEnd(detailsState.period)
          }
          detailsState.duration = timeUtil.periodToDuration(detailsState.period)
        } else {
          return
        }
      }

      // when changing pauses, make sure that pausesFixed is set to true
      if (key === 'pauses' && !detailsState.pausesFixed) {
        detailsState.pausesFixed = true
      }

      return {
        detailsState,
        shouldAutoFocus
      }
    },
    // do the following post-processing after updating the state:
    () => {
      // remove the 'position-not-selected' error if we selected a position
      if (key === 'positionId') {
        if (this.state.errors && this.state.errors.includes('position-not-selected')) {
          const newErrs = this.state.errors || []
          newErrs.splice(newErrs.indexOf('position-not-selected'), 1)
          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
        }
      }

      // remove the 'agenda-name-missing' error if all the new agenda items have a name
      if (key === 'agenda') {
        if (this.state.errors && this.state.errors.includes('agenda-name-missing')) {
          if (!val || val.length === 0 || !val.some(ag => !ag.name || ag.name.trim() === '')) {
            const newErrs = this.state.errors || []
            newErrs.splice(newErrs.indexOf('agenda-name-missing'), 1)
            this.setState((s) => Object.assign({}, s, { errors: newErrs }))
          }
        }
      }

      // remove the 'attribute-on-shift-and-agenda' error if none of the agenda items have attributes
      if (key === 'agenda') {
        if (this.state.errors && this.state.errors.includes('attribute-on-shift-and-agenda')) {
          if (!val || val.length === 0 || !val.some(ag => (ag.customAttributes && ag.customAttributes.length))) {
            const newErrs = this.state.errors || []
            newErrs.splice(newErrs.indexOf('attribute-on-shift-and-agenda'), 1)
            this.setState((s) => Object.assign({}, s, { errors: newErrs }))
          }
        }
      }
      // remove the 'attribute-on-shift-and-agenda' error if the shift has no attributes
      const supportedAttributes = calendarUtil.getSupportedShiftAttributes(this.props.workspace, this.state.detailsState)
      if (key === 'customAttributes' || supportedAttributes.some(ca => ca.id === key)) {
        if ((!this.state.detailsState.customAttributes || this.state.detailsState.customAttributes.length === 0) && (!supportedAttributes.filter(sa => sa.readOnly).some(sa => !!this.state[sa.id]))) {
          const newErrs = this.state.errors || []
          newErrs.splice(newErrs.indexOf('attribute-on-shift-and-agenda'), 1)
          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
        }
      }

      // remove the 'agenda-overlaps-pause' error if agenda tasks no longer overlap pauses
      if (key === 'agenda' || key === 'pauses') {
        if (this.state.errors && this.state.errors.includes('agenda-overlaps-pause')) {
          if (!this.state.detailsState.pauses || !this.state.detailsState.pauses.length || !this.state.detailsState.agenda || !this.state.detailsState.agenda.some(t => this.state.detailsState?.pauses?.some(p => t.start < (p.start + p.duration) && (t.start + t.duration) > p.start) /* task t overlaps with some pause p */)) {
            const newErrs = this.state.errors || []
            newErrs.splice(newErrs.indexOf('agenda-overlaps-pause'), 1)
            this.setState((s) => Object.assign({}, s, { errors: newErrs }))
          }
        }
      }

      // remove the 'agenda-overlaps-task' error if agenda tasks no longer overlap each other
      if (key === 'agenda') {
        if (this.state.errors && this.state.errors.includes('agenda-overlaps-task')) {
          if (!this.state.detailsState.agenda || !this.state.detailsState.agenda.some((t1, i1) => this.state.detailsState?.agenda?.some((t2, i2) => i2 !== i1 && t1.start < (t2.start + t2.duration) && (t1.start + t1.duration) > t2.start) /* task t1 overlaps with some other task t2 */)) {
            const newErrs = this.state.errors || []
            newErrs.splice(newErrs.indexOf('agenda-overlaps-task'), 1)
            this.setState((s) => Object.assign({}, s, { errors: newErrs }))
          }
        }
      }

      // remove the 'transfer-ws-from-missing' and 'transfer-ws-to-missing' errors if they were selected
      if (key === 'createTransferFrom') {
        if (this.state.errors && this.state.errors.includes('transfer-ws-from-missing')) {
          const newErrs = this.state.errors || []
          newErrs.splice(newErrs.indexOf('transfer-ws-from-missing'), 1)
          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
        }
      }
      if (key === 'createTransferTo') {
        if (this.state.errors && this.state.errors.includes('transfer-ws-to-missing')) {
          const newErrs = this.state.errors || []
          newErrs.splice(newErrs.indexOf('transfer-ws-to-missing'), 1)
          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
        }
      }

      // reload the potential dummy warnings
      setTimeout(() => {
        this.refreshWarnings()
      }, 500)
    })
  }

  _selectNewestPosition () {
    const positions = this.props.customPositions || this.props.positions
    if (positions.length) {
      this.setVal('positionId', positions[positions.length - 1].id)
    }
  }

  _selectFirstEmployee () {
    const { employees } = this.props
    const emps = this.state.detailsState.positionId
      ? Object.keys(employees).map(id => employees[id]).filter((emp) => emp.positions.find((pos) => pos.id === this.state.detailsState.positionId))
      : Object.keys(employees).map(id => employees[id])

    if (emps.length) {
      this.setVal('userId', emps[0].id)
    }
  }

  _getStartTimeStr (s) {
    if (typeof s === 'string' && s.indexOf(',') !== -1) {
      s = s.replace(',', '.')
    }
    const h = Math.floor(s)
    const m = Math.round((s % 1) * 60)
    return h.toString() + t('HOUR_SHORTEST') + (m > 0 ? ' ' + m.toString() + t('MINUTE_SHORTEST') : '')
  }

  _applyTemplate (template) {
    const tpl = JSON.parse(JSON.stringify(template.shift))
    for (const [key, value] of Object.entries(tpl)) {
      if (key === 'period') { // special treatment for the 'period' prop of the template - the date from template needs to be ignored. only the hours & minutes are important.
        const sd = this.props.newShift
          ? this.getDetailsObject()
          : this.props.shiftDetail

        const newPeriod = (sd?.period)
          ? Object.assign({}, sd.period)
          : (this.state.detailsState && this.state.detailsState.period)
            ? Object.assign({}, this.state.detailsState.period)
            : this.getDefaultDetails().period

        let newStart
        let newEnd
        if (tpl.period && tpl.period.start) newStart = moment(newPeriod.start).clone().hour(moment(tpl.period.start).hour()).minute(moment(tpl.period.start).minute())
        if (tpl.period && tpl.period.end) newEnd = moment(newPeriod.end).clone().hour(moment(tpl.period.end).hour()).minute(moment(tpl.period.end).minute())

        this.setVal(key, { start: newStart, end: newEnd })
      } else {
        this.setVal(key, value)
      }
    }
  }

  _getDetailsObject () {
    const { type, shiftDetail } = this.props
    let details = this.state.detailsState
    if (type === 'template' || type === 'shiftTemplate' || type === 'cycle') {
      details = this.state.detailsState // for template or shiftTemplate, only use the state.detailsState
    } else {
      if (shiftDetail) {
        details = Object.assign({}, shiftDetail, this.state.detailsState) // for live, try using props.shiftDetails as well
      }
    }
    if (!details.period && typeof details.start === 'number' && typeof details.duration === 'number') {
      const pd = details.day ? moment(details.day) : moment().startOf('day')
      details.period = {
        start: pd.clone().add(details.start, 'minutes'),
        end: pd.clone().add((details.start + details.duration), 'minutes')
      }
    }
    if (typeof details.idealSkill !== 'undefined' && !isNaN(parseInt(details.idealSkill))) details.idealSkill = parseInt(details.idealSkill)
    return details
  }

  render () {
    const {
      isLoading,
      employees,
      day,
      hour,
      userId,
      startingOffer,
      openedFromCalControls,
      setSidebar,
      setModal,
      setCalendarMultiSelect,
      type,
      newShift,
      newShiftTemplate,
      newAttendance,
      workspace,
      isPluginEnabled,
      customPositions,
      hidePausesSection,
      createShiftTemplate,
      updateShiftTemplate,
      shiftTemplateId,
      me,
      deleteCycleDetail
    } = this.props
    const isAdmin = !!document.querySelector('.ds-adminpanel')

    let { disabledEditation } = this.props
    const { newTemplateName, errors } = this.state

    const positions = customPositions || this.props.positions

    // set the contents of 'details' accordingly
    const details = this.getDetailsObject()

    // prepare the localityOptions
    const localitiesOnWS = workspace && workspace.localities && workspace.localities
    let localityOptions = [{
      value: null,
      label: t('LOCALITY_SHIFT_NOT_AT_LOC')
    }].concat((localitiesOnWS || []).map(loc => {
      return {
        value: loc.id,
        label: (loc.shortName ? '(' + loc.shortName + ') ' : '') + loc.name
      }
    }))

    // filter out the localityOptions that shouldn't be available, when WS has enforcedLocalities and I have a specific subset of localities assigned

    if (workspace?.enforceLocalities && workspace?.localities?.length) {
      const myLocalities = workspace.localities.filter(wsLoc => wsLoc.assigns?.some(ass => ass.userId === me.id))
      if (myLocalities.length !== 0) {
        localityOptions = localityOptions.filter(l => {
          if (!l.value) return true
          if (l.value === details.localityId) return true
          return myLocalities.some(ml => ml.id === l.value)
        })
      }
    }

    const employeeOnShift = details
      ? type === 'transfer'
        ? this.state.transferOriginWorkspaceEmps && this.state.transferOriginWorkspaceEmps.find(ee => ee.id === details.userId)
        : employees[details.userId]
      : null

    const positionsList = positions.filter(pos => !pos.archived).map((pos) => {
      return {
        label: (
          <Position
            name={pos.name}
            color={pos.color}
          />
        ),
        value: pos.id
      }
    })
    if (!customPositions && permissionsUtil.canWrite(PERMISSIONS.WORKSPACE.POSITIONS)) {
      positionsList.push({
        label: '+ ' + t('ADD_POSITION'),
        value: 'newPosition'
      })
    }

    const supportedAttributes = calendarUtil.getSupportedShiftAttributes(workspace, details)
    const attribs = calendarUtil.getShiftAttributes(details, workspace)

    const displayPauses = details.pausesFixed // || (details.pauses && details.pauses.length)
    const defaultPauses = timeUtil.getDefaultShiftBreaks({ details, workspace })

    // compute some duration-related numbers for the blue panel
    const workMinutes = (Array.isArray(details?.stats)
      ? details?.stats.reduce((a, s) => { return a + s?.workMinutes }, 0)
      : null)
    const durationMinutes = (!details?.period?.start || !details?.period?.end)
      ? 0
      : moment(
        moment(details.period.end).format('HH:mm') > moment(details.period.start).format('HH:mm')
          ? moment(moment(details.period.start).format('YYYY-MM-DD') + 'T' + moment(details.period.end).format('HH:mm'))
          : moment(moment(details.period.start).format('YYYY-MM-DD') + 'T' + moment(details.period.end).format('HH:mm')).add(1, 'day')
      ).diff(details.period.start, 'minutes')
    if ((newShift || newShiftTemplate) && !details.pausesFixed) { // when we're creating a new shift and haven't yet switched pauses to manual, display numbers for default pauses in the blue bar
      details.pauses = defaultPauses
    }
    const pausesAreCountedAsWork = (durationMinutes === workMinutes && details?.pauses?.length && details?.pauses.some(p => p?.duration > 0)) // in some countries, pauses are counted as work minutes (they don't subtract from workMinutes). there, we won't display the pause-related bracket in the blue panel.
    let overtimeDurationMinutes = null
    if (details?.overTime?.duration) {
      overtimeDurationMinutes = details?.overTime?.duration
      if (details?.pauses?.length && !isNaN(details?.overTime?.start)) {
        const mergedPauses = miscUtil.getMergedEventsWithStartsAndDurations(details?.pauses)
        const overtimePausesOverlap = mergedPauses.reduce((acc, pause) => {
          const pauseEnd = pause.start + pause.duration
          const overtimeEnd = details?.overTime.start + details?.overTime.duration
          const overlapStart = Math.max(pause.start, details?.overTime.start)
          const overlapEnd = Math.min(pauseEnd, overtimeEnd)
          const overlapDuration = Math.max(0, overlapEnd - overlapStart)
          return acc + overlapDuration
        }, 0)
        overtimeDurationMinutes = Math.max(0, overtimeDurationMinutes - overtimePausesOverlap)
      }
    }

    // disable editation of the live shift if it's in a locked period
    if (type === 'live' && !disabledEditation) {
      if (details?.period?.start && miscUtil.isMyCalendarLocked(details.period.start, workspace)) disabledEditation = 'disabled-due-to-locked-period'
    }

    // disable editation
    if (type === 'shiftTemplate' && !permissionsUtil.canWrite(PERMISSIONS.WORKSPACE.SHIFTTEMPLATES)) {
      disabledEditation = 'disabled-due-to-missing-template-write-permission'
    }

    // options for the shift attributes dropdown
    const optsAttribs = supportedAttributes.map((sf, sfi) => {
      return {
        value: sf.id,
        label: <ShiftAttribute key={'sf_' + sfi} attribute={sf} hideDeletion />
      }
    })

    // prepare the dropdown/input for employee selection
    let employeeSelectionContent = null

    // Single employee selection Dropdown
    if (
      !['template', 'shiftTemplate', 'cycle'].includes(type) &&
      day !== 'day-template' &&
      (!newShift || userId || openedFromCalControls || newAttendance || type === 'transfer')
    ) {
      let optsEmps = []
      const empsForDropdown = (type === 'transfer')
        ? this.state.transferOriginWorkspaceEmps
        : employees ? Object.values(employees) : null

      if (empsForDropdown) {
        optsEmps = sortUtil.sortEmployees(empsForDropdown.filter(ee => !ee.external))
          .map(ee => { return { label: ee.name, value: ee.id, data: ee } })
          .map(ee => {
            // divide emps into APPROPRIATE and OTHERS if we have
            // some position or locaion selected
            if (details.positionId || details.locationId) {
              if (details.positionId && (!ee.data.positions || !ee.data.positions.find(p => (p.id === details.positionId) && (isNaN(details.idealSkill) || details.idealSkill <= p.skill)))) return Object.assign({}, ee, { groupTitle: t('INAPPROPRIATE_CANDIDATES') })
              if (details.localityId && (!ee.data.localities || !ee.data.localities.includes(details.localityId))) return Object.assign({}, ee, { groupTitle: t('INAPPROPRIATE_CANDIDATES') })
              return Object.assign({}, ee, { groupTitle: t('APPROPRIATE_CANDIDATES') })
            }
            return ee
          })
          .sort((a, b) => {
            // custom sorting to make sure the appropriate candidates are displayed at the top
            if (a.groupTitle && b.groupTitle) {
              if (a.groupTitle === t('INAPPROPRIATE_CANDIDATES') && b.groupTitle === t('APPROPRIATE_CANDIDATES')) return 1
              if (a.groupTitle === t('APPROPRIATE_CANDIDATES') && b.groupTitle === t('INAPPROPRIATE_CANDIDATES')) return -1
            }
            return 0
          })
      }

      employeeSelectionContent = (
        <div className='ds-es-emp-dropdown'>
          <Dropdown
            label={t('EMPLOYEE')}
            singleSelect
            searchable
            sortedOptions={false}
            size={Dropdown.SIZES.LARGE}
            type={Dropdown.TYPES.VARIABLE}
            style={Dropdown.STYLES.LIGHT}
            options={optsEmps}
            value={employeeOnShift ? [{ label: employeeOnShift.name, value: employeeOnShift.id }] : []}
            placeholder={t('EMPLOYEE')}
            onChange={(v) => {
              this.setVal('userId', v.value)

              // remove the 'user-not-selected' error if we selected a user
              if (this.state.errors.includes('user-not-selected')) {
                const newErrs = this.state.errors || []
                newErrs.splice(newErrs.indexOf('user-not-selected'), 1)
                this.setState((s) => Object.assign({}, s, { errors: newErrs }))
              }
            }}
            disabled={disabledEditation}
            hasError={errors && errors.includes('user-not-selected')}
            errorMessage={(errors && errors.includes('user-not-selected')) ? t('SELECT_USER_REQUIRED') : null}
          />
        </div>
      )
    }

    // No. of copies (if creating new shift into 'unassigned' row)
    if (newShift && !userId && !startingOffer && !openedFromCalControls && !newAttendance && !['transfer', 'cycle'].includes(type)) {
      employeeSelectionContent = (
        <Input
          type={Input.TYPES.NUMBER}
          required
          label={t('NEW_SHIFT_COPIES')}
          min={1}
          max={20}
          placeholder='1'
          onChange={(v) => {
            this.setVal('copiesCount', v)
          }}
          value={details.copiesCount}
        />
      )
    }

    return (
      <Modal
        size={Modal.SIZES.L}
        className={`ds-modal-shift is-${type}`}
        isLoading={isLoading}
        extraHeaderButtons={[
          ((details && !newShift && !shiftTemplateId && !disabledEditation) && (
            /* Extra actions, such as DELETE or COPY */
            (day !== 'day-template' && type !== 'template' && type !== 'cycle' && (
              <Tooltip
                key={0}
                position={Tooltip.POSITIONS.BOTTOM}
                text={t('COPY_SHIFT')}
                anchor={
                  <Icon
                    ico={Icon.ICONS.duplicate}
                    onClick={(e) => {
                      setCalendarMultiSelect({
                        action: 'copy',
                        isSelectingTargets: true,
                        sourceEvents: [Object.assign({}, details, { day: moment(details.period.start).format('YYYY-MM-DD') })],
                        targets: []
                      })
                      setModal(null)
                    }}
                    size={Icon.SIZES.SMALL}
                  />
                }
              />
            )),
            (<Spacing key='space' type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_8} />),
            (
              <Tooltip
                key={1}
                position={Tooltip.POSITIONS.BOTTOM}
                text={t('DELETE_SHIFT')}
                anchor={
                  <Icon
                    testid='delete-shift-button'
                    ico={Icon.ICONS.delete}
                    color={Icon.COLORS.RED}
                    size={Icon.SIZES.SMALL}
                    onClick={(e) => {
                      if (type !== 'cycle') {
                        this.deleteShift(details)
                      } else {
                        deleteCycleDetail({
                          data: details
                        })
                        setModal(null)
                      }
                    }}
                  />
                }
              />
            )
          )),
          (type === 'shiftTemplate' && !newShiftTemplate && !disabledEditation && (
            <Tooltip
              key={2}
              position={Tooltip.POSITIONS.BOTTOM}
              text={t('DELETE')}
              anchor={
                <Icon
                  ico={Icon.ICONS.delete}
                  color={Icon.COLORS.RED}
                  size={Icon.SIZES.SMALL}
                  onClick={() => {
                    updateShiftTemplate(shiftTemplateId, null, null)
                    setModal(null)
                  }}
                />
              }
            />
          )),
          (isAdmin ? (
            <Tooltip
              key={3} position={Tooltip.POSITIONS.BOTTOM}
              text={t('DETAILED_REPORT')}
              anchor={
                <Icon
                  onClick={() => this.setState(s => {
                    s.showDetailedStats = !s.showDetailedStats

                    return s
                  })}
                  ico='items'
                />
              }
            />) : null
          )
        ].filter(Boolean)}
        headerContent={this.state.showDetailedStats
          ? (
            <div className='ds-title'>
              {t('DETAILED_STATISTIC')} - {employees[details.userId].name} {moment(details.period.start).format('DD.MM.YYYY')} {moment(details.period.start).format('HH:mm')} - {moment(details.period.end).format('HH:mm')}
            </div>
          ) : details ? (
            <Fragment>
              <div className='ds-title'>
                {newShift
                  ? type === 'transfer'
                    ? t('EDIT_SHIFT_CREATE_TRANSFER_TITLE')
                    : t('ADD')
                  : type === 'shiftTemplate'
                    ? (newShiftTemplate ? t('SHIFT_TEMPLATES_NEW_MODAL_TITLE') : t('SHIFT_TEMPLATE'))
                    : details && details.period && moment(details.period.start).isBefore(moment()) && isPluginEnabled('attendance') && type !== 'cycle'
                      ? t('EDIT_ATTENDANCE')
                      : t('EDIT_SHIFT')}
              </div>
              <div style={{ marginLeft: '0.5rem' }}>{<this.props.CheckboxShift1_5Toggle />}</div>
              {/* Switcher: Add Shift/Unavailability */}
              {newShift && !['template', 'cycle', 'transfer'].includes(type)
                ? (
                  <Switcher
                    value={false}
                    onSelect={(value) => !!value && setModal(
                      'extra-unavailability',
                      {
                        newAvailabilityOrTimeOff: 'timeOff',
                        day: details.period && details.period.start ? moment(details.period.start).format('YYYY-MM-DD') : day,
                        hour,
                        userId: details.userId || userId,
                        isAttendancePage: window.location && window.location.pathname && window.location.pathname.includes('attendance'),
                        afterSubmit: this.props.afterSubmit
                      }
                    )}
                    options={[
                      {
                        value: false,
                        label: newAttendance
                          ? t('ADD_SWITCHER_ATTENDANCE')
                          : details && details.period && moment(details.period.start).isBefore(moment()) && isPluginEnabled('attendance')
                            ? t('ADD_SWITCHER_ATTENDANCE')
                            : t('ADD_SWITCHER_SHIFT')
                      },
                      {
                        value: 'extra-unavailability',
                        label: t('ADD_SWITCHER_UNAV')
                      }
                    ]}
                  />
                )
                : null}
            </Fragment>
          ) : null}
        footerContent={details ? (
          <Fragment>
            {type === 'shiftTemplate'
              ? (
                // buttons for when we're editing/creating a shift template from 'edit-shift-template'/'extra-shift-template' modal (on /workspace/shift-templates page)
                <Fragment>
                  <Button
                    label={this.state.showDetailedStats ? t('BACK') : t('CLOSE')}
                    onClick={() => setModal(null)}
                    size={Button.SIZES.LARGE}
                  />
                  <Button
                    size={Button.SIZES.LARGE}
                    style={Button.STYLES.CONTAINED}
                    color={newShiftTemplate ? Button.COLORS.GREEN : Button.COLORS.PRIMARY}
                    label={t('SAVE')}
                    disabled={!this.canSave() || disabledEditation || this.state.showDetailedStats}
                    loading={this.state.isSaving}
                    onClickActiveWhenDisabled={!disabledEditation}
                    onClick={() => {
                      const newErrs = this.state.errors || []
                      let updating = false

                      if (this.state.errors) {
                        // update the state of the 'position-not-selected' error
                        if (!details.positionId) {
                          if (!newErrs.includes('position-not-selected')) {
                            newErrs.push('position-not-selected')
                            updating = true
                          }
                        }
                        // update the state of the 'shift-template-name-missing' error
                        if (!newTemplateName || newTemplateName.trim() === '') {
                          if (!newErrs.includes('shift-template-name-missing')) {
                            newErrs.push('shift-template-name-missing')
                            updating = true
                          }
                        }

                        // update the state of the 'agenda-name-missing' error
                        if (details.agenda && details.agenda.some(ag => !ag.name || ag.name.trim() === '')) {
                          const newErrs = this.state.errors || []
                          if (!newErrs.includes('agenda-name-missing')) newErrs.push('agenda-name-missing')
                          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                        }

                        // update the state of the 'agenda-overlaps-pause' error
                        if (details.pauses && details.pauses.length && details.agenda && details.agenda.some(t => details?.pauses?.some(p => t.start < (p.start + p.duration) && (t.start + t.duration) > p.start) /* task t overlaps with some pause p */)) {
                          const newErrs = this.state.errors || []
                          if (!newErrs.includes('agenda-overlaps-pause')) newErrs.push('agenda-overlaps-pause')
                          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                        }

                        // update the state of the 'attribute-on-shift-and-agenda' error
                        if (details.agenda && details.agenda.length) {
                          const someAgendaPointHasAttributes = details.agenda.some(ag => ag.overTime || (ag.customAttributes && ag.customAttributes.length))
                          if (attribs.length && someAgendaPointHasAttributes) {
                            const newErrs = this.state.errors || []
                            if (!newErrs.includes('attribute-on-shift-and-agenda')) newErrs.push('attribute-on-shift-and-agenda')
                            this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                          }
                        }

                        // update the state of the 'agenda-overlaps-task' error
                        if (details.agenda && details.agenda.some((t1, i1) => details.agenda.some((t2, i2) => i2 !== i1 && t1.start < (t2.start + t2.duration) && (t1.start + t1.duration) > t2.start) /* task t1 overlaps with some pause other task t2 */)) {
                          const newErrs = this.state.errors || []
                          if (!newErrs.includes('agenda-overlaps-task')) newErrs.push('agenda-overlaps-task')
                          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                        }
                      }

                      if (updating) {
                        this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                      }

                      // actually save/create the shift template
                      if (this.canSave()) {
                        // fix the period: if the end is before the start in newly created shift
                        let fixedDetails = details
                        if (details?.period?.start && details?.period?.end) {
                          if (moment(details.period.start).isAfter(moment(details.period.end))) {
                            const newDs = details
                            newDs.period.end = moment(newDs.period.end).add(1, 'days').format()
                            fixedDetails = newDs
                            this.setState((s) => Object.assign({}, s, { detailsState: newDs }))
                          }
                        }

                        if (newShiftTemplate) {
                          createShiftTemplate(fixedDetails, newTemplateName)
                        } else {
                          updateShiftTemplate(shiftTemplateId, fixedDetails, newTemplateName)
                        }
                        setModal(null)
                      }
                    }}
                  />

                </Fragment>
              )
              : (
                // buttons for when we're actually editing a shift (usually, but not only, from the calendar)
                <Fragment>
                  <Button
                    label={t('CLOSE')}
                    onClick={() => setModal(null)}
                    size={Button.SIZES.LARGE}
                  />
                  <Button
                    size={Button.SIZES.LARGE}
                    style={Button.STYLES.CONTAINED}
                    color={newShift ? Button.COLORS.GREEN : Button.COLORS.PRIMARY}
                    label={newShift ? t('ADD') : newAttendance ? t('ADD_EXTRA_ATTENDANCE') : t('SAVE')}
                    testid={newShift ? 'add-shift-button' : 'save-shift-button'}
                    disabled={!this.canSave() || disabledEditation}
                    loading={this.state.isSaving}
                    onClickActiveWhenDisabled
                    onClick={() => {
                      if (disabledEditation) return

                      // save or create the shift if possible
                      const canSave = this.canSave()
                      if (canSave) this.saveShift()

                      if (this.state.errors) {
                        // update the state of the 'position-not-selected' error
                        if (!details.positionId) {
                          const newErrs = this.state.errors || []
                          if (!newErrs.includes('position-not-selected')) newErrs.push('position-not-selected')
                          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                        }

                        // update the state of the 'user-not-selected' error
                        if (!details.userId && (newAttendance || type === 'transfer')) {
                          const newErrs = this.state.errors || []
                          if (!newErrs.includes('user-not-selected')) newErrs.push('user-not-selected')
                          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                        }

                        // update the stat of the 'agenda-name-missing' error
                        if (details.agenda && details.agenda.some(ag => !ag.name || ag.name.trim() === '')) {
                          const newErrs = this.state.errors || []
                          if (!newErrs.includes('agenda-name-missing')) newErrs.push('agenda-name-missing')
                          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                        }

                        // update the state of the 'agenda-overlaps-pause' error
                        if (details.pauses && details.pauses.length && details.agenda && details.agenda.some(t => details?.pauses?.some(p => t.start < (p.start + p.duration) && (t.start + t.duration) > p.start) /* task t overlaps with some pause p */)) {
                          const newErrs = this.state.errors || []
                          if (!newErrs.includes('agenda-overlaps-pause')) newErrs.push('agenda-overlaps-pause')
                          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                        }

                        // update the state of the 'attribute-on-shift-and-agenda' error
                        if (details.agenda && details.agenda.length) {
                          const someAgendaPointHasAttributes = details.agenda.some(ag => ag.overTime || (ag.customAttributes && ag.customAttributes.length))
                          if (attribs.length && someAgendaPointHasAttributes) {
                            const newErrs = this.state.errors || []
                            if (!newErrs.includes('attribute-on-shift-and-agenda')) newErrs.push('attribute-on-shift-and-agenda')
                            this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                          }
                        }

                        // update the state of the 'agenda-overlaps-task' error
                        if (details.agenda && details.agenda.some((t1, i1) => details.agenda.some((t2, i2) => i2 !== i1 && t1.start < (t2.start + t2.duration) && (t1.start + t1.duration) > t2.start) /* task t1 overlaps with some pause other task t2 */)) {
                          const newErrs = this.state.errors || []
                          if (!newErrs.includes('agenda-overlaps-task')) newErrs.push('agenda-overlaps-task')
                          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                        }

                        // update the state of the 'transfer-ws-from-missing' and 'transfer-ws-to-missing' error
                        if (type === 'transfer' && !details.createTransferFrom) {
                          const newErrs = this.state.errors || []
                          if (!newErrs.includes('transfer-ws-from-missing')) newErrs.push('transfer-ws-from-missing')
                          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                        }
                        if (type === 'transfer' && !details.createTransferTo) {
                          const newErrs = this.state.errors || []
                          if (!newErrs.includes('transfer-ws-to-missing')) newErrs.push('transfer-ws-to-missing')
                          this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                        }
                      }
                    }}
                  />
                </Fragment>
              )}

            {details.id
              ? (
                <div
                  style={{
                    position: 'absolute',
                    left: '1ex',
                    bottom: '1ex',
                    fontSize: '65%',
                    color: 'rgb(204, 204, 204)'
                  }}
                  onClick={() => {
                    miscUtil.copyToClipBoard(details.id)
                  }}
                >
                  id: {details.id}
                </div>
              )
              : null}
          </Fragment>) : null}

        sections={this.state.showDetailedStats ? [<DetailedStatistics key='detailed-stats' details={details} />] : details ? [
          // Warnings
          (employeeOnShift ? miscUtil.getEventWarningsForEditModal(details, isPluginEnabled('laborlaw')) : null),

          // Transfer Creation
          (type === 'transfer'
            ? (
              <TransferCreation
                detailsState={this.state.detailsState}
                handleChange={this.setVal}
                setOriginWorkspaceEmps={(v) => { this.setState(s => Object.assign({}, s, { transferOriginWorkspaceEmps: v })) }}
                errors={this.state.errors}
              />
            )
            : null),

          // Shift Template
          (type !== 'transfer' // not displayed when creating a transfer
            ? (
              <ShiftTemplates
                key='modal-section-template'
                workspace={workspace}
                positions={positions}
                type={type}
                disabledEditation={disabledEditation}
                newTemplateName={newTemplateName}
                setNewTemplateName={(v) => { this.setState(s => Object.assign({}, s, { newTemplateName: v })) }}
                applyTemplate={this.applyTemplate}
                errors={this.state.errors}
                setErrors={(newErrs) => { this.setState((s) => Object.assign({}, s, { errors: newErrs })) }}
                detailsState={this.state.detailsState}
                shiftDetail={this.props.shiftDetail}
              />)
            : null),

          <Fragment key='modal-section-temporal'>
            {/* Employee / Date / Time / Pauses */}

            {/* Employee */}
            {employeeSelectionContent}

            {/* Date */}
            {day !== 'day-template' && type !== 'template' && type !== 'shiftTemplate' && type !== 'cycle'
              ? (
                <Input
                  required
                  type='date'
                  ico={Icon.ICONS.calendar}
                  label={t('DATE')}
                  onChange={(v) => {
                    this.setVal('period', {
                      start: moment(v + ' ' + moment(details.period.start).format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
                      end: moment(v + ' ' + moment(details.period.end).format('HH:mm'), 'YYYY-MM-DD HH:mm').format()
                    }, 'date')
                  }}
                  autoFocus={this.state.shouldAutoFocus === 'date'}
                  value={details.period ? moment(details.period.start).format('YYYY-MM-DD') : null}
                  disabled={disabledEditation}
                />
              )
              : null}

            {/* Time */}
            <Input
              label={t('SHIFT_START')}
              type='time'
              ico={Icon.ICONS.clock}
              required
              onKeyDown={(e) => {
                const inp = document.createElement('input')
                inp.setAttribute('type', 'time')
                const timeSupported = (inp.type === 'time')
                if (e.key === 'ArrowUp' && !timeSupported) {
                  this.setVal('period', {
                    start: moment(details.period.start).add(15, 'minutes').format(),
                    end: moment(details.period.end).format()
                  })
                }
                if (e.key === 'ArrowDown' && !timeSupported) {
                  this.setVal('period', {
                    start: moment(details.period.start).add(-15, 'minutes').format(),
                    end: moment(details.period.end).format()
                  })
                }
              }}
              onChange={(v) => {
                this.setVal('period', {
                  start: moment(moment(details.period.start).format('YYYY-MM-DD') + ' ' + v, 'YYYY-MM-DD HH:mm').format(),
                  end: moment(details.period.end).format()
                }, 'start')
              }}
              autoFocus={this.state.shouldAutoFocus === 'start'}
              value={details.period ? moment(details.period.start).format('HH:mm') : null}
              disabled={disabledEditation}
            />
            <Input
              label={t('SHIFT_END')}
              type='time'
              ico={Icon.ICONS.clock}
              required
              onKeyDown={(e) => {
                const inp = document.createElement('input')
                inp.setAttribute('type', 'time')
                const timeSupported = (inp.type === 'time')

                if (e.key === 'ArrowUp' && !timeSupported) {
                  this.setVal('period', {
                    start: moment(details.period.start).format(),
                    end: moment(details.period.end).add(15, 'minutes').format()
                  })
                }
                if (e.key === 'ArrowDown' && !timeSupported) {
                  this.setVal('period', {
                    start: moment(details.period.start).format(),
                    end: moment(details.period.end).add(-15, 'minutes').format()
                  })
                }
              }}
              onChange={(v) => {
                this.setVal('period', {
                  end: moment(moment(details.period.end).format('YYYY-MM-DD') + ' ' + v, 'YYYY-MM-DD HH:mm').format(),
                  start: moment(details.period.start).format()
                }, 'end')
              }}
              autoFocus={this.state.shouldAutoFocus === 'end'}
              value={details.period ? moment(details.period.end).format('HH:mm') : null}
              disabled={disabledEditation}
            />

            {/* Blue bar with duration info (only possible if we know userId - otherwise, we can't call dummyShift request to compute stats) */}
            {(details?.userId && workMinutes !== null) ? (
              <div className='ds-shiftinfo-duration'>
                {this.state.isLoadingDummyData
                  ? (<Icon ico={Icon.ICONS.loader} size={Icon.SIZES.SMALL} />)
                  : (
                    <Flex align={Flex.POSITION.CENTER} stretch>
                      <Icon ico={Icon.ICONS.clock} size={Icon.SIZES.SMALL} />
                      <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_4} />
                      {details.period
                        ? (
                          <Flex justify={Flex.POSITION.SPC_BETWEEN} stretch>
                            <Flex>
                              {/* work minutes */}
                              <Text weight={Text.WEIGHTS.BOLD} type={Text.TYPES.BODY_MEDIUM} color={Text.COLORS.PRIMARY}>
                                {t('WORK_TIME') + ': ' + formatHours(workMinutes / 60)}
                              </Text>

                              {/* overtime */}
                              {details?.overTime
                                ? (
                                  <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_4}>
                                    <Text type={Text.TYPES.BODY_MEDIUM} color={Text.COLORS.PRIMARY}>
                                      {
                                        ' (' +
                                        this._getStartTimeStr((workMinutes - (overtimeDurationMinutes !== null ? overtimeDurationMinutes : workMinutes)) / 60) +
                                        ' + ' +
                                        t('OVERTIME') +
                                        ' ' +
                                        this._getStartTimeStr((overtimeDurationMinutes !== null ? overtimeDurationMinutes : workMinutes) / 60) +
                                        ')'
                                      }
                                    </Text>
                                  </Spacing>
                                )
                                : null}
                            </Flex>

                            {/* total shift duration, including pauses */}
                            {!pausesAreCountedAsWork && (
                              <Flex>
                                <Text type={Text.TYPES.BODY_MEDIUM} color={Text.COLORS.PRIMARY} align={Text.ALIGN.RIGHT}>
                                  {t('WORK_TIME_WITH_PAUSES') + ' (' + formatHours((details?.pauses?.reduce((a, s) => { return a + (s?.duration || 0) }, 0)) / 60) + ')'}
                                </Text>
                                <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_4}>
                                  <Text weight={Text.WEIGHTS.BOLD} type={Text.TYPES.BODY_MEDIUM} color={Text.COLORS.PRIMARY}>
                                    {'= ' + formatHours(durationMinutes / 60)}
                                  </Text>
                                </Spacing>
                              </Flex>
                            )}
                          </Flex>)
                        : null}
                    </Flex>
                  )}
              </div>
            ) : null}

            {/* Pauses */}
            {!hidePausesSection && (
              <div className='ds-shiftinfo-pauses'>
                <div style={{ fontWeight: '700', margin: '0.25rem 0' }}>
                  {t('PAUSES_SHORT')}
                </div>
                <Switcher
                  value={details.pausesFixed}
                  onSelect={(value) => {
                    this.setVal('pauses', value ? defaultPauses : [])
                    this.setVal('pausesFixed', value)
                  }}
                  options={[
                    {
                      label: (
                        <>
                          {t('EDIT_SHIFT_PAUSES_AUTO')}
                          <Spacing
                            type={Spacing.TYPES.HORIZONTAL}
                            size={Spacing.SIZES.SIZE_2}
                          >
                            <HelpTooltip text={t('EDIT_SHIFT_PAUSES_AUTO_TT')} />
                          </Spacing>
                        </>
                      ),
                      value: false
                    },
                    {
                      label: t('EDIT_SHIFT_PAUSES_MANUAL'),
                      value: true
                    }
                  ]}
                  disabled={disabledEditation}
                />

                {displayPauses
                  ? details.pauses.map((p, idx) => {
                    return (
                      <Fragment key={'pause_' + idx.toString()}>

                        <Flex align={Flex.POSITION.CENTER}>
                          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_14} />
                          <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_4}>
                            <Text weight={Text.WEIGHTS.BOLD} type={Text.TYPES.BODY_MEDIUM}>
                              {parseInt(idx) + 1}{'. '}
                              {t('PAUSE') + ' ' + t('IN') + ' '}
                              {moment(details.period.start).add(p.start, 'minutes').format('H:mm ')}
                              {'(' + t('PAUSE_AFTER')} {this._getStartTimeStr((p.start / 60)) + ') '}
                              {!isNaN(p.duration) && (`${t('PAUSE_DUR')} ${formatHours(p.duration / 60)}`)}
                            </Text>
                          </Spacing>
                          <div style={{ flexGrow: 1 }} />
                          {!disabledEditation && (
                            <div
                              className='ds-link'
                              onClick={(e) => {
                                const newPauses = details.pauses.filter((p, j) => j !== idx)
                                this.setVal('pauses', newPauses)
                              }}
                            >
                              <Text weight={Text.WEIGHTS.BOLD} color={Text.COLORS.DANGER} type={Text.TYPES.BODY_MEDIUM}>
                                <Flex align={Flex.POSITION.CENTER}>
                                  <Icon
                                    ico={Icon.ICONS.delete}
                                    size={Icon.SIZES.SMALL}
                                  />
                                  {t('DELETE')}
                                </Flex>
                              </Text>
                            </div>
                          )}
                          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_6} />
                        </Flex>

                        <div className='ds-s-p-pause'>
                          <Input
                            type='time'
                            size={Input.SIZES.FULL_WIDTH}
                            ico={Icon.ICONS.clock}
                            value={moment(details.period.start).add(p.start, 'minutes').format('HH:mm')}
                            onChange={(val) => {
                              const newPauses = details.pauses.map((p, j) => {
                                if (j === idx) {
                                  const startMoment = moment(
                                    moment(details.period.start)
                                      .add((val >= moment(details.period.start).format('HH:mm') ? 0 : 1), 'days')
                                      .format('YYYY-MM-DD') + ' ' + val,
                                    'YYYY-MM-DD HH:mm')
                                  return {
                                    duration: p.duration,
                                    start: moment(startMoment).diff(details.period.start, 'minutes')
                                  }
                                } else {
                                  return p
                                }
                              })
                              this.setVal('pauses', newPauses)
                            }}
                            disabled={disabledEditation}
                          />
                          <Input
                            type='number'
                            size={Input.SIZES.FULL_WIDTH}
                            extraContent={t('MINUTE_SHORT')}
                            ico={Icon.ICONS.clock}
                            min={1}
                            max={240}
                            value={numberUtil.removeZeroBefore(p.duration)}
                            onChange={(val) => {
                              const newPauses = details.pauses.map((p, j) => {
                                if (j === idx) {
                                  return { duration: val ? parseInt(val) : 0, start: p.start }
                                } else {
                                  return p
                                }
                              })
                              this.setVal('pauses', newPauses)
                            }}
                            disabled={disabledEditation}
                          />
                        </div>
                      </Fragment>
                    )
                  })
                  : null}

                {/* PAUSES_IMPORTANT */}
                {displayPauses && details.pauses.length < 4 && details.pausesFixed && !disabledEditation
                  ? (
                    <div
                      style={{ paddingTop: (details.pauses.length === 0 ? '1.6ex' : null) }}
                      className='ds-shiftinfo-pauses-add'
                    >
                      <span
                        className='ds-link'
                        onClick={(e) => {
                          const newPauses = [...details.pauses] // make a copy of pauses array
                          newPauses.push((defaultPauses?.length ? defaultPauses[0] : null) || { start: 120, duration: 30 })
                          this.setVal('pauses', newPauses)
                        }}
                      >
                        <Text weight={Text.WEIGHTS.BOLD} color={Text.COLORS.PRIMARY} type={Text.TYPES.BODY_MEDIUM}>
                          {'+ ' + t('PAUSE_ADD')}
                        </Text>
                      </span>
                    </div>
                  )
                  : null}

              </div>
            )}

            {/* Position */}
            <Dropdown
              label={t('POSITION')}
              singleSelect
              searchable
              type={Dropdown.TYPES.VARIABLE}
              style={Dropdown.STYLES.LIGHT}
              size={Dropdown.SIZES.LARGE}
              value={positionsList.find((pos) => pos.value === details.positionId)}
              placeholder={t('SELECT_JOB_POSITION')}
              hasError={this.state.errors && this.state.errors.includes('position-not-selected')}
              errorMessage={this.state.errors && this.state.errors.includes('position-not-selected') ? t('SELECT_JOB_POSITION_REQUIRED' + (type === 'shiftTemplate' ? '_TPL' : '')) : null}
              onChange={({ value: posId }) => {
                if (posId === 'newPosition') {
                  setSidebar('position-editor', { afterSubmit: (form) => this.selectNewestPosition() })
                } else {
                  this.setVal('positionId', posId)
                  if (this.state.errors && this.state.errors.includes('position-not-selected')) {
                    const newErrs = this.state.errors || []
                    newErrs.splice(newErrs.indexOf('position-not-selected'), 1)
                    this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                  }
                }
              }}
              options={positionsList}
              onBlur={() => {
                if (!details.positionId) {
                  const newErrs = this.state.errors || []
                  if (!newErrs.includes('position-not-selected')) newErrs.push('position-not-selected')
                  this.setState((s) => Object.assign({}, s, { errors: newErrs }))
                }
              }}
              disabled={disabledEditation}
            />
            <SkillSelect
              value={(details.idealSkill === null || details.idealSkill === undefined) ? 'any' : details.idealSkill}
              label={t('SKILL')}
              size={Dropdown.SIZES.LARGE}
              onChange={(val) => { this.setVal('idealSkill', val) }}
              disabled={disabledEditation}
            />

            {/* Locality */}
            {isPluginEnabled('localities') && localitiesOnWS && localitiesOnWS.length
              ? (
                <Dropdown
                  label={t('LOCALITY')}
                  singleSelect
                  searchable
                  type={Dropdown.TYPES.VARIABLE}
                  style={Dropdown.STYLES.LIGHT}
                  size={Dropdown.SIZES.LARGE}
                  options={localityOptions}
                  value={localityOptions.find(o => o.value === details.localityId)}
                  placeholder={localityOptions.find(o => o.value === details.localityId)?.label}
                  onChange={(loc) => {
                    this.setVal('localityId', loc.value)
                  }}
                  disabled={disabledEditation}
                />
              )
              : null}

            {/* Shift Attributes */}
            {type !== 'template'
              ? (
                <>
                  <Dropdown
                    hasError={this.state.errors && this.state.errors.includes('attribute-on-shift-and-agenda')}
                    errorMessage={this.state.errors && this.state.errors.includes('attribute-on-shift-and-agenda') ? t('EDIT_SHIFT_AGENDA_ATTRIBUTE_ON_SHIFT') : null}
                    label={t('SHIFT_FLAGS')}
                    searchable
                    sortedOptions={false}
                    size={Dropdown.SIZES.LARGE}
                    type={Dropdown.TYPES.VARIABLE}
                    style={Dropdown.STYLES.LIGHT}
                    disabled={disabledEditation}
                    options={optsAttribs}
                    value={optsAttribs.filter(oa => !!attribs.find(a => a.id === oa.value))}
                    placeholder={
                      (attribs && attribs.length)
                        ? (
                          <>
                            {attribs.slice(0, 2).map((flag, flagIdx) => {
                              return (
                                <ShiftAttribute
                                  short
                                  key={'sf_' + flagIdx}
                                  attribute={flag}
                                  hideDeletion={disabledEditation}
                                  onClickDelete={(e) => {
                                    if (supportedAttributes.find(sa => sa.readOnly && sa.id === flag.id)) {
                                      this.setVal(flag.id, false)
                                    } else {
                                      this.setVal('customAttributes', details.customAttributes.filter(ca => ca.attributeId !== flag.id))
                                    }
                                    e.stopPropagation()
                                  }}
                                />
                              )
                            })}
                            {attribs.length >= 3 && <div>&nbsp;...</div>}
                          </>)
                        : t('SELECT_FLAGS')
                    }
                    onChange={(vals) => {
                      const newCustomAttributes = []
                      supportedAttributes.forEach(sa => {
                        const enabled = Boolean(vals.find(v => v.value === sa.id))
                        if (sa.readOnly) {
                          // either change the value directly on shift (if it's one of the predefined readOnly attributes) ...
                          this.setVal(sa.id, enabled)
                        } else {
                          // ... or change the shift's 'customAttributes' array (if it's one of the custom attributes)
                          if (enabled) newCustomAttributes.push({ attributeId: sa.id, value: true })
                        }
                      })
                      this.setVal('customAttributes', newCustomAttributes)
                    }}
                  />

                  {attribs.filter(att => att.description || att.readOnly).length > 0 && (
                    <div className='ds-shiftinfo-flag-descriptions'>
                      {attribs.filter(att => att.description || att.readOnly).map((att, attIdx) => (
                        <span key={'flag_desc_' + attIdx}>
                          {att.readOnly ? t('SHIFT_FLAG_DESC.' + att.id) : att.description.replace(new RegExp('[ .]+$'), '') + '.'}
                        </span>
                      ))}
                    </div>
                  )}
                </>
              ) : null}

            {/* Contracts */}
            <ContractTypes
              workspaceContractTypes={workspace ? workspace.contractTypes : []}
              employee={employeeOnShift}
              shiftStart={details.period ? details.period.start : null}
              valueType={details.contractType}
              valueId={this.getSelectedContractId(details, employeeOnShift)}
              handleChange={this.setVal}
              disabled={disabledEditation}
            />

            {/* Note */}
            {type !== 'template'
              ? (
                <Input
                  className='ds-shiftinfo-note'
                  label={t('NOTE')}
                  onChange={(v) => {
                    this.setVal('note', v)
                  }}
                  value={details.note}
                  placeholder=''
                  disabled={disabledEditation}
                  testid='note-input'
                />
              )
              : null}

            {/* Agenda */}
            {isPluginEnabled('agenda') && (['live', 'template', 'shiftTemplate', 'cycle'].includes(type)) && (
              <Agenda
                workspace={workspace}
                positionsList={positionsList.filter(p => p.value !== 'newPosition').concat({
                  value: null,
                  label: t('SORT_BY_POSITION_NO_POS')
                })}
                supportedAttributes={supportedAttributes}
                details={details}
                handleChange={this.setVal}
                errors={this.state.errors}
                disabled={disabledEditation}
              />
            )}

            {/* Checkboxes (for OverTime & StandBy enabling/disabling) */}
            {type !== 'template' && (
              <>
                <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_4} />
                <Flex align={Flex.POSITION.CENTER}>
                  <Checkbox
                    checked={details?.overTime !== null}
                    disabled={disabledEditation}
                    onChange={(v) => {
                      const defaultOverTime = { // defaults
                        ordered: true,
                        unpaid: false,
                        paidTOIL: false,
                        start: null,
                        duration: null
                      }

                      const newOverTime = v ? defaultOverTime : null
                      this.setVal('overTime', newOverTime)
                    }}
                  >
                    {t('OVERTIME')}
                  </Checkbox>
                  <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_20} />
                  {(isPluginEnabled('standby') || details.standBy) && (
                    <Checkbox
                      checked={Boolean(details?.standBy)}
                      disabled={disabledEditation}
                      onChange={(v) => {
                        if (v) {
                          this.setVal('standBy', true)
                          this.setVal('standByActivities', [])
                        } else {
                          this.setVal('standBy', false)
                          this.setVal('standByActivities', [])
                        }
                      }}
                    >
                      {t('SHIFT_FLAG.standBy')}
                    </Checkbox>
                  )}
                </Flex>

                {/* OverTime */}
                <OverTime
                  value={details.overTime}
                  handleChange={this.setVal}
                  disabled={disabledEditation}
                  shiftPeriod={details.period}
                />

                {/* Standby */}
                {(details.standBy && type !== 'shiftTemplate' && type !== 'template' && type !== 'cycle'
                  ? (
                    <StandBy
                      handleChange={this.setVal}
                      disabled={disabledEditation}
                      standByActivities={details.standByActivities}
                      period={details.period}
                    />
                  )
                  : null)}
              </>
            )}
          </Fragment>

        ].filter(Boolean) : []}
      />
    )
  }
}

export default connect(withUseDevTestingCurrentShiftModal(EditShift))
