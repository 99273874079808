import isLoading from './is-loading'
import setCalendarFilters from '../action/set-calendar-filters'

export default (calendarFilters) => {
  return async (dispatch) => {
    dispatch(isLoading('set-calendar-filters'))

    console.log('setCalendarFilters', calendarFilters)

    // update the store.calendarFilters
    await dispatch(setCalendarFilters(calendarFilters))

    dispatch(isLoading('set-calendar-filters', true))
  }
}
